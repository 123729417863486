import { TextInput, Textarea } from '@mantine/core';

import { usePracticeFormContext } from '../PracticeFormContext';

export default function StepOne() {
  const form = usePracticeFormContext();

  return (
    <>
      <TextInput
        label="Name"
        my="md"
        size="md"
        placeholder="My Practice"
        withAsterisk
        autoFocus
        key={form.key('name')}
        {...form.getInputProps('name')}
      />
      <Textarea
        label="Description"
        autosize
        minRows={4}
        size="md"
        placeholder="This practice is..."
        key={form.key('description')}
        {...form.getInputProps('description')}
      />
    </>
  );
}
