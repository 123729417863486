import { useState } from 'react';
import {
  ActionIcon,
  Badge,
  Button,
  Group,
  Input,
  NumberInput,
  SimpleGrid,
  Stack,
  Stepper,
  Textarea,
  TextInput,
} from '@mantine/core';
import { randomId } from '@mantine/hooks';
import { IconArrowDown, IconArrowUp, IconPlus, IconTrash } from '@tabler/icons-react';

import Form, { useFormContext } from '../common/Form';
import CategorySelect from '../common/inputs/CategorySelect';
import FileUpload from '../common/FileUpload';
import TipTap from '../common/TipTap';

import classes from './DrillForm.module.css';

const inputProps = {
  size: 'md',
  mb: 'md',
};

const validate = (values, step) => {
  const errors = {};

  if (step === 0) {
    if (!values.name) {
      errors.name = 'Name is required';
    }

    if (!values.description) {
      errors.description = 'Description is required';
    }

    if (!values.video) {
      errors.video = 'Video is required';
    }
  }

  if (step === 1) {
    if (!values.category_id) {
      errors.category_id = 'Category is required';
    }

    if (!values.shooters) {
      errors.shooters = 'Shooters is required';
    } else if (values.shooters < 1 || values.shooters > 3) {
      errors.shooters = 'Shooters must be between 1 and 3';
    }

    if (!values.level) {
      errors.level = 'Level is required';
    } else if (values.level < 1 || values.level > 3) {
      errors.level = 'Level must be between 1 and 3';
    }
  }

  if (step === 2) {
    if (!values.reps) {
      errors.reps = 'Reps are required';
    }

    if (!values.setup) {
      errors.setup = 'Setup is required';
    }

    if (!values.instruction) {
      errors.instruction = 'Instructions are required';
    }
  }

  return errors;
};

const transformInitialValues = (initialValues) => {
  const { requirements = [''], ...rest } = initialValues;
  return {
    ...rest,
    requirements: (requirements || []).map((requirement) => ({ id: randomId(), value: requirement || '' })),
  };
};

const transformValues = (values) => {
  const { requirements = [''], ...rest } = values;
  return {
    ...rest,
    requirements: (requirements || []).map((requirement) => requirement.value).filter(Boolean),
  };
};

const RequirementsInput = () => {
  const form = useFormContext();

  const addRequirement = () => {
    form.insertListItem('requirements', { id: randomId(), value: '' });
  };

  const removeRequirement = (index) => {
    form.removeListItem('requirements', index);
  };

  const handleMoveUp = (index) => {
    form.reorderListItem('requirements', { from: index, to: index - 1 });
  };

  const handleMoveDown = (index) => {
    form.reorderListItem('requirements', { from: index, to: index + 1 });
  };

  return (
    <Input.Wrapper label="Requirements" {...inputProps}>
      <Stack mt="xs" mx="xs">
        {form.getValues().requirements.map((requirement, index, requirements) => {
          return (
            <Group key={requirement.id} gap="xs">
              <Badge variant="outline" circle>
                {index + 1}
              </Badge>
              <TextInput
                size="sm"
                placeholder="Enter a requirement"
                flex={1}
                miw={200}
                {...form.getInputProps(`requirements.${index}.value`)}
                key={form.key(`requirements.${index}.value`)}
              />
              <ActionIcon.Group m="auto">
                <ActionIcon
                  size="input-sm"
                  variant="default"
                  onClick={() => handleMoveUp(index)}
                  disabled={index === 0}
                  className={classes.actionIcon}
                >
                  <IconArrowUp size={20} />
                </ActionIcon>
                <ActionIcon
                  size="input-sm"
                  variant="default"
                  onClick={() => handleMoveDown(index)}
                  disabled={index === requirements.length - 1}
                  className={classes.actionIcon}
                >
                  <IconArrowDown size={20} />
                </ActionIcon>
                <ActionIcon size="input-sm" variant="default" c="red" onClick={() => removeRequirement(index)}>
                  <IconTrash size={20} />
                </ActionIcon>
              </ActionIcon.Group>
            </Group>
          );
        })}
        <Group>
          <Button variant="light" size="sm" leftSection={<IconPlus size={14} />} onClick={addRequirement}>
            Add
          </Button>
        </Group>
      </Stack>
    </Input.Wrapper>
  );
};

const Toolbar = ({ step, setStep, loading, disabled, handleClose, cancelLabel, submitLabel }) => {
  const form = useFormContext();

  const handleBack = () => {
    if (step === 0) {
      handleClose();
      return;
    }
    setStep((prev) => prev - 1);
  };

  const handleNext = () => {
    if (step === 2) {
      form.onSubmit();
      return;
    }

    if (form.validate().hasErrors) return;

    setStep((prev) => prev + 1);
  };

  return (
    <Group mt="xl" justify="space-between">
      <Button variant="outline" onClick={handleBack}>
        {step === 0 ? cancelLabel : 'Back'}
      </Button>
      <Button loading={loading} disabled={disabled} onClick={handleNext}>
        {step === 2 ? submitLabel : 'Next'}
      </Button>
    </Group>
  );
};

const DrillForm = ({ initialValues, toolbarProps, ...props }) => {
  const [step, setStep] = useState(0);

  return (
    <>
      <Form
        validate={(values) => validate(values, step)}
        initialValues={transformInitialValues(initialValues)}
        transformValues={transformValues}
        Toolbar={Toolbar}
        toolbarProps={{ ...toolbarProps, step, setStep }}
        {...props}
      >
        <Stepper size="xs" active={step} onStepClick={setStep} allowNextStepsSelect={!!initialValues.id}>
          <Stepper.Step>
            <TextInput source="name" label="Name" placeholder="Enter a name" data-autofocus {...inputProps} />
            <Textarea
              source="description"
              label="Description"
              placeholder="Enter description"
              autosize
              minRows={5}
              maxRows={10}
              {...inputProps}
            />
            <FileUpload source="video" label="Video" type="video" hidePreview {...inputProps} />{' '}
          </Stepper.Step>
          <Stepper.Step key={1}>
            <CategorySelect source="category_id" {...inputProps} />
            <SimpleGrid cols={2}>
              <NumberInput source="shooters" label="Shooters" placeholder="# of shooters" min={1} max={3} {...inputProps} />
              <NumberInput source="level" label="Level" placeholder="Enter level" min={1} max={3} {...inputProps} />
            </SimpleGrid>
            <RequirementsInput />
          </Stepper.Step>
          <Stepper.Step key={2}>
            <TextInput source="reps" label="Reps" {...inputProps} />
            <TipTap source="setup" label="Setup" {...inputProps} />
            <TipTap source="instruction" label="Instructions" {...inputProps} />
            <TipTap source="coaching_points" label="Coaching Points" {...inputProps} />
          </Stepper.Step>
        </Stepper>
      </Form>
    </>
  );
};

export default DrillForm;
