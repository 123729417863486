import { Textarea, TextInput } from '@mantine/core';

import Form from '../common/Form';
import FileUpload from '../common/FileUpload';

const validate = (values) => {
  const errors = {};

  if (!values.title) {
    errors.title = 'Title is required';
  }

  if (!values.content) {
    errors.content = 'Content is required';
  }

  return errors;
};

const AnnouncementForm = (props) => {
  return (
    <Form validate={validate} {...props}>
      <TextInput source="title" label="Title" placeholder="Enter a title" size="md" mb="md" data-autofocus />
      <Textarea
        source="content"
        label="Content"
        placeholder="Enter content"
        size="md"
        mb="md"
        autosize
        minRows={5}
        maxRows={10}
      />
      <FileUpload source="image_url" label="Image" type="image" />
    </Form>
  );
};

export default AnnouncementForm;
