import { useContext } from 'react';
import { Box, Button, CloseIcon, NumberInput, SimpleGrid } from '@mantine/core';

import useMediaQuery from '../../hooks/useMediaQuery';
import CategorySelect from '../common/inputs/CategorySelect';
import LevelSelect from '../common/inputs/LevelSelect';

import { DrillsContext } from './DrillsProvider';
import { SearchInput } from './MobileFilters';

const inputProps = {
  size: 'md',
  comboboxProps: { shadow: 'xl' },
};

const DesktopFilters = () => {
  const isMobile = useMediaQuery('sm');
  const { filters, hasFilters, handleFiltersChange, clearFilters } = useContext(DrillsContext);

  if (isMobile) return null;

  return (
    <Box mb="lg">
      <SimpleGrid type="container" cols={{ base: 1, '600px': 2, '900px': 4 }}>
        <SearchInput label="Search" />
        <CategorySelect
          defaultEnabled={false}
          value={filters.category}
          onChange={(value) => handleFiltersChange('category', value)}
          {...inputProps}
        />

        <LevelSelect value={filters.level} onChange={(value) => handleFiltersChange('level', value)} {...inputProps} />
        <NumberInput
          value={filters.shooters}
          placeholder="# of shooters"
          label="Shooters"
          min={1}
          max={3}
          onChange={(value) => handleFiltersChange('shooters', value, false)}
          size="md"
          error={!filters?.shooters && 'Required'}
        />
      </SimpleGrid>
      {hasFilters && (
        <Button size="xs" color="red" mt="sm" onClick={clearFilters} leftSection={<CloseIcon size={16} />}>
          Clear
        </Button>
      )}
    </Box>
  );
};

export default DesktopFilters;
