import { Box } from '@mantine/core';
import WidgetBot from '@widgetbot/react-embed';

const serverId = import.meta.env.VITE_DISCORD_SERVER_ID;
const channelId = import.meta.env.VITE_DISCORD_CHANNEL_ID;

export default function Discussions() {
  return (
    <Box h="calc(100dvh - var(--app-shell-header-height) - var(--app-shell-padding) * 2)">
      <WidgetBot server={serverId} channel={channelId} width="100%" height="100%" />
    </Box>
  );
}
