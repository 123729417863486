import { useMemo } from 'react';
import { Container, Group } from '@mantine/core';

import PageHeader from '../components/common/PageHeader';
import DataTable from '../components/common/DataTable';
import { AddCategoryDialogButton, EditCategoryDialogButton } from '../components/category/CategoryDialog';
import { DeleteModalButton } from '../components/common/DeleteModal';

export default function AdminCategories() {
  const columns = useMemo(
    () => [
      {
        accessor: 'name',
      },
      {
        accessor: 'group',
        render: ({ group }) => group || '-',
      },
      {
        accessor: 'created_at',
        title: 'Created At',
      },
      {
        accessor: 'actions',
        title: '',
        textAlign: 'right',
        render: (record) => (
          <Group justify="right" wrap="no-wrap">
            <EditCategoryDialogButton initialValues={record} />
            <DeleteModalButton record={record} />
          </Group>
        ),
      },
    ],
    [],
  );

  return (
    <Container size="xl" p={0}>
      <PageHeader title="Categories" actions={[<AddCategoryDialogButton key="create-category" />]} />
      <DataTable columns={columns} sortBy="group" />
    </Container>
  );
}
