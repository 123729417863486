import { AspectRatio } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';

import supabase from '../../config/supabaseClient';
import CustomCard from '../common/CustomCard';
import CustomReactPlayer from '../common/CustomReactPlayer';

const DrillOfTheWeek = () => {
  const {
    data: drillOfTheWeek,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['drill'],
    queryFn: async () =>
      await supabase
        .from('drill')
        .select('*')
        .eq('is_drill_of_the_week', true)
        .single()
        .throwOnError()
        .then(({ data }) => data),
  });

  return (
    <CustomCard title="Drill of the Week" isLoading={isLoading} isError={isError}>
      <AspectRatio ratio={16 / 9}>
        <CustomReactPlayer url={drillOfTheWeek?.video} data-hideshadow />
      </AspectRatio>
    </CustomCard>
  );
};

export default DrillOfTheWeek;
