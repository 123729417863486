import { useDisclosure } from '@mantine/hooks';

import { CreateModal, EditModal, OpenAddModalButton, OpenEditModalButton } from '../common/ResourceModal';
import StopPropagation from '../common/StopPropagation';

import TemplateForm from './TemplateForm';

export const AddTemplateDialog = (props) => (
  <CreateModal {...props}>
    <TemplateForm />
  </CreateModal>
);

export const EditTemplateDialog = (props) => (
  <EditModal {...props}>
    <TemplateForm />
  </EditModal>
);

export const AddTemplateDialogButton = (props) => {
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <StopPropagation>
      <OpenAddModalButton onClick={open} />
      <AddTemplateDialog opened={opened} onClose={close} {...props} />
    </StopPropagation>
  );
};

export const EditTemplateDialogButton = (props) => {
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <StopPropagation>
      <OpenEditModalButton onClick={open} iconOnly />
      <EditTemplateDialog opened={opened} onClose={close} {...props} />
    </StopPropagation>
  );
};
