import { useNavigate } from 'react-router-dom';
import { Button, Container, PasswordInput, Stack } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useForm } from '@mantine/form';

import CustomCard from '../components/common/CustomCard';
import supabase from '../config/supabaseClient';

export default function ResetPassword() {
  const navigate = useNavigate();
  const form = useForm({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validate: {
      newPassword: (val) => (val.length <= 6 ? 'Password should include at least 6 characters' : null),
      confirmPassword: (val) => (form.values.newPassword && val !== form.values.newPassword ? 'Passwords do not match' : null),
    },
  });

  const handleSubmit = async (values) => {
    const { newPassword } = values || {};
    const { error } = await supabase.auth.updateUser({ password: newPassword });

    if (!error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to change your password',
        color: 'red',
      });
      return;
    }

    notifications.show({
      title: 'Password changed',
      message: 'Your password has been successfully changed',
      color: 'green',
    });
    navigate('/my-profile');
  };

  return (
    <Container size="xs">
      <CustomCard title="Reset Password">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Stack>
            <PasswordInput
              label="New Password"
              placeholder="Your new password"
              key={form.key('newPassword')}
              {...form.getInputProps('newPassword')}
            />
            <PasswordInput
              label="Confirm Password"
              placeholder="Confirm your new password"
              key={form.key('confirmPassword')}
              {...form.getInputProps('confirmPassword')}
            />
          </Stack>
          <Button fullWidth mt="sm" type="submit">
            Submit
          </Button>
        </form>
      </CustomCard>
    </Container>
  );
}
