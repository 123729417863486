import { Loader, NumberInput, Select, SimpleGrid } from '@mantine/core';
import { useUncontrolled } from '@mantine/hooks';
import { useQuery } from '@tanstack/react-query';

import supabase from '../../config/supabaseClient';

import Form from '../common/Form';
import CategorySelect from '../common/inputs/CategorySelect';

const inputProps = {
  size: 'md',
  mb: 'md',
};

const validate = (values) => {
  const errors = {};

  if (!values.template_id) {
    errors.template_id = 'Template is required';
  }

  if (!values.category_id) {
    errors.category_id = 'Category is required';
  }

  if (!values.order) {
    errors.order = 'Order is required';
  }

  if (!values.count) {
    errors.count = 'Count is required';
  }

  return errors;
};

const TemplateInput = ({ value, defaultValue, onChange, ...props }) => {
  const [_value, setValue] = useUncontrolled({ value, defaultValue, onChange });

  const { data: templates, isPending } = useQuery({
    queryKey: ['templates'],
    queryFn: async () => await supabase.from('template').select('*').order('created_at', { ascending: true }).throwOnError(),
    select: ({ data }) => data.map((template) => ({ value: String(template.id), label: template.name })),
  });

  return (
    <Select
      value={_value && String(_value)}
      onChange={setValue}
      data={templates}
      label="Template"
      placeholder="Select a template"
      rightSection={isPending && <Loader size={18} />}
      searchable
      clearable
      {...props}
    />
  );
};

const TemplateMemberForm = ({ disabledValues, ...props }) => {
  return (
    <Form validate={validate} {...props}>
      <TemplateInput source="template_id" disabled {...inputProps} />
      <CategorySelect source="category_id" disabledValues={disabledValues} {...inputProps} />
      <SimpleGrid cols={2}>
        <NumberInput source="order" label="Order" placeholder="1" min={0} {...inputProps} />
        <NumberInput source="count" label="Count" placeholder="1" min={0} {...inputProps} />
      </SimpleGrid>
    </Form>
  );
};

export default TemplateMemberForm;
