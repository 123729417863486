import { Container } from '@mantine/core';

import PageHeader from '../components/common/PageHeader';

import { DrillsProvider } from '../components/drills/DrillsProvider';
import DrillCatalog from '../components/drills/DrillCatalog';
import DesktopFilters from '../components/drills/DesktopFilters';
import MobileFilters from '../components/drills/MobileFilters';

export default function Drills() {
  return (
    <Container size="xl" p={0}>
      <DrillsProvider>
        <PageHeader title="Drills" />
        <DesktopFilters />
        <MobileFilters />
        <DrillCatalog />
      </DrillsProvider>
    </Container>
  );
}
