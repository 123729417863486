import { Container, Grid } from '@mantine/core';

import PageHeader from '../components/common/PageHeader';
import DrillOfTheWeek from '../components/home/DrillOfTheWeek';
import Announcements from '../components/home/Announcements';
import FavoritePractices from '../components/home/FavoritePractices';

export default function Home() {
  return (
    <Container size="xl" p={0}>
      <PageHeader title="Home" />
      <Grid>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <DrillOfTheWeek />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <Announcements />
        </Grid.Col>
        <Grid.Col span={12}>
          <FavoritePractices />
        </Grid.Col>
      </Grid>
    </Container>
  );
}
