import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ActionIcon, Group, List, Text } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';

import { useSubscription } from '../../context/SessionContext';
import CustomCard from '../common/CustomCard';
import supabase from '../../config/supabaseClient';
import { PendingShareIcon, SharedIcon } from '../practices/PracticeShow';
import SharePlan from './SharePlan';

const DeletedButton = ({ sharedStripe }) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async () => supabase.from('shared_stripe').delete().eq('id', sharedStripe?.id).throwOnError(),
    onError: () => {
      notifications.show({
        title: 'Error',
        message: 'Failed to remove user from organization',
        color: 'red',
      });
    },
    onSuccess: () => {
      notifications.show({
        title: 'User removed',
        message: 'User removed from organization',
        color: 'green',
      });

      queryClient.invalidateQueries(['shared_stripe']);
    },
  });

  if (!sharedStripe) return null;
  return (
    <ActionIcon size="xs" variant="subtle" color="red" onClick={() => mutation.mutate()}>
      <IconX />
    </ActionIcon>
  );
};

export const OrganizationCard = ({ ...props }) => {
  const subscription = useSubscription();
  const subscriptionId = subscription?.id;

  const {
    data: sharedStripes,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['shared_stripe', subscriptionId],
    queryFn: () =>
      supabase
        .from('shared_stripe')
        .select('*')
        .eq('stripe_id', subscriptionId)
        .then(({ data }) => data),
  });

  return (
    <CustomCard title="Organization" isLoading={isLoading} isError={isError} {...props}>
      <Text size="sm" c="dimmed" mb="xs">
        {`You have invited ${sharedStripes?.length || 0} out of 20 users`}
      </Text>
      {sharedStripes && (
        <List spacing="xs" size="sm">
          {sharedStripes.map((shared) => (
            <List.Item key={shared.id} icon={shared.shared_user_id ? <SharedIcon /> : <PendingShareIcon />}>
              <Group gap="xs">
                <Text size="sm">{shared.shared_email}</Text>
                <DeletedButton sharedStripe={shared} />
              </Group>
            </List.Item>
          ))}
        </List>
      )}
      <SharePlan mt="sm" />
    </CustomCard>
  );
};
