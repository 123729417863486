import { useState } from 'react';
import { Button, Group, Modal } from '@mantine/core';
import { useField } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IconMailForward } from '@tabler/icons-react';

import { fetchApi } from '../../api';
import { useSessionContext, useUser } from '../../context/SessionContext';
import EmailInput from '../common/inputs/EmailInput';
import { isEmail, isNotOwnEmail } from '../practices/SharePractice';

const SharePlanDialog = ({ opened = false, setOpen, handleShare }) => {
  const user = useUser();
  const field = useField({
    initialValue: '',
    validateOnBlur: true,
    validate: (val) => isEmail(val) || isNotOwnEmail(val, user.email),
  });

  const handleClick = async () => {
    const invalid = await field.validate();
    if (invalid) return;
    handleShare({ email: field.getValue() });
  };

  return (
    <Modal opened={opened} title="Invite User" onClose={() => setOpen(false)} zIndex={9999}>
      <EmailInput placeholder="email@domain.com" {...field.getInputProps()} />
      <Group justify="space-between" mt="lg">
        <Button variant="outline" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button onClick={handleClick}>Send</Button>
      </Group>
    </Modal>
  );
};

const SharePlan = ({ ...props }) => {
  const { session } = useSessionContext();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);

  const mutation = useMutation({
    mutationFn: async ({ email }) => {
      await fetchApi(session, '/share-plan', {
        method: 'POST',
        body: JSON.stringify({ email }),
      });
      return { email };
    },
    onError: (err, { email }) => {
      const message =
        err.code === 'PLAN_ALREADY_SHARED' ? 'Plan already shared with this user' : `Error sharing plan to ${email}`;

      notifications.show({
        title: 'Error',
        message,
        color: 'red',
      });
    },
    onSuccess: ({ email }) => {
      notifications.show({
        title: 'Plan shared',
        message: `Plan shared to ${email}`,
        color: 'green',
      });

      queryClient.invalidateQueries(['shared_stripe']);
    },
    onSettled: () => {
      setOpen(false);
    },
  });

  return (
    <>
      <Button
        size="xs"
        leftSection={<IconMailForward size={16} />}
        onClick={() => setOpen(true)}
        loading={mutation.isPending}
        {...props}
      >
        Invite
      </Button>
      {open && <SharePlanDialog opened={open} setOpen={setOpen} handleShare={(values) => mutation.mutate(values)} />}
    </>
  );
};

export default SharePlan;
