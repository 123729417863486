import { Navigate, Outlet } from 'react-router-dom';

import { useUser } from '../../context/SessionContext';

export const isLoggedIn = (user) => user?.aud === 'authenticated';

const LoggedInRoutes = () => {
  const user = useUser();

  if (!isLoggedIn(user)) {
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};

export default LoggedInRoutes;
