import { Badge, Chip, Container, Group, Paper, SimpleGrid, Slider, Text, UnstyledButton } from '@mantine/core';
import { useQueries } from '@tanstack/react-query';

import { fetchDurations, fetchExperiences } from './supabase';
import { usePracticeFormContext } from '../PracticeFormContext';
import { EXPERIENCE_COLOR } from '../../../constants';

import classes from './Stepper.module.css';

export const Section = Paper.withProps({ bg: 'gray.2', p: 'sm', withBorder: true });

const RequiredShootersInput = ({ shooters, c }) => {
  const { setValues } = usePracticeFormContext();

  const setShooters = (value) => {
    setValues({ shooters: value });
  };

  return (
    <>
      <Text size="lg" fw={500} mb={4} c={c}>
        How many shooters?
      </Text>
      <Slider
        value={shooters}
        onChange={setShooters}
        min={0}
        max={3}
        step={1}
        thumbSize={18}
        mb="xl"
        marks={[
          { value: 0, label: '0' },
          { value: 1, label: '1' },
          { value: 2, label: '2' },
          { value: 3, label: '3+' },
        ]}
      />
    </>
  );
};

export default function StepTwo() {
  const { errors, getValues, setValues } = usePracticeFormContext();
  const { level, duration, shooters } = getValues();

  const { experiences, durations, isPending } = useQueries({
    queries: [
      {
        queryKey: ['experience'],
        queryFn: fetchExperiences,
      },
      {
        queryKey: ['duration'],
        queryFn: fetchDurations,
      },
    ],
    combine: (results) => {
      return {
        experiences: results[0],
        durations: results[1],
        isPending: results.some((result) => result.isPending),
      };
    },
  });

  const textColor = (key) => (errors[key] ? 'red' : 'black');

  const setLevel = (value) => {
    if (level === value) {
      setValues({ level: null });
    } else {
      setValues({ level: value });
    }
  };

  const setDuration = (value) => {
    if (duration === value) {
      setValues({ duration: null });
    } else {
      setValues({ duration: value });
    }
  };

  if (isPending) return null;

  return (
    <Container size="sm" mt="xl" p={0}>
      <Text size="lg" fw={500} mb={4} c={textColor('level')}>
        Select a Level
      </Text>
      <Section mb="xl">
        <SimpleGrid type="container" cols={{ base: 1, '400px': 2, '600px': 3 }}>
          {experiences.data.map(({ id, name, years }) => (
            <Paper
              key={id}
              className={classes.Paper}
              component={UnstyledButton}
              p="md"
              flex="1 1 150px"
              ta="center"
              shadow="sm"
              onClick={() => setLevel(id)}
              mod={{ selected: level === id }}
            >
              <Text size="xl" fw={700} mb="xs">
                {name}
              </Text>
              <Badge size="lg" color={EXPERIENCE_COLOR[name]} mx="auto">
                {years}
              </Badge>
            </Paper>
          ))}
        </SimpleGrid>
      </Section>
      <Text size="lg" fw={500} mb={4} c={textColor('duration')}>
        Select a Duration
      </Text>
      <Section mb="xl">
        <Group gap="sm">
          {durations.data.map(({ id, name }) => {
            const checked = duration === id;
            return (
              <Chip
                key={id}
                size="lg"
                variant={checked ? 'filled' : 'outline'}
                checked={checked}
                onChange={() => setDuration(id)}
              >
                <Text fw={500} size="sm">
                  {name}
                </Text>
              </Chip>
            );
          })}
        </Group>
      </Section>
      <RequiredShootersInput shooters={shooters} c={textColor('shooters')} />
    </Container>
  );
}
