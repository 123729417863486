import { Center, Loader } from '@mantine/core';

const CustomLoader = (props) => {
  return (
    <Center h="100%">
      <Loader {...props} />
    </Center>
  );
};

export default CustomLoader;
