import { Fragment, useCallback, useMemo, useState } from 'react';
import { AspectRatio, CloseButton, Divider, Grid, Group, Image, InputWrapper, Paper, Skeleton, Text } from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE, MIME_TYPES } from '@mantine/dropzone';
import { IconPhoto, IconUpload, IconX } from '@tabler/icons-react';

import { useFormContext } from './Form';
import CustomReactPlayer from './CustomReactPlayer';

import classes from './FileUpload.module.css';

const SIZE_MB = 1024 ** 2;
const SIZE_KB = 1024;
const MAX_FILE_SIZE = 5 * SIZE_MB;

const ACCEPTED_FILES = {
  image: IMAGE_MIME_TYPE,
  video: [MIME_TYPES.mp4],
  all: [...IMAGE_MIME_TYPE, MIME_TYPES.mp4],
};

const MediaPreview = ({ file }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { url, isVideo } = useMemo(() => {
    if (!file) return { url: null, isVideo: false };
    const fileUrl = typeof file === 'string' ? file : URL.createObjectURL(file);
    const isVideoFile =
      fileUrl.match(/\.(mp4|webm|ogg|)$/) || (file.type && file.type.startsWith('video/')) || fileUrl.includes('yout.be'); // checks for youtube links
    return { url: fileUrl, isVideo: isVideoFile };
  }, [file]);

  const handleLoad = useCallback(() => {
    setLoading(false);
    if (typeof file !== 'string' && url) {
      URL.revokeObjectURL(url);
    }
  }, [file, url]);

  const handleError = useCallback(() => {
    setLoading(false);
    setError(true);
  }, []);

  if (!url) return null;

  return (
    <AspectRatio ratio={16 / 9}>
      {loading && !isVideo && <Skeleton h="100%" w="100%" />}
      {!error &&
        (isVideo ? (
          <CustomReactPlayer url={url} onReady={handleLoad} onError={handleError} />
        ) : (
          <Image src={url} onLoad={handleLoad} onError={handleError} mod={{ loading }} classNames={{ root: classes.preview }} />
        ))}
    </AspectRatio>
  );
};

const SelectedFiles = ({ urls, handleRemove }) => {
  if (!urls?.length) return null;

  const files = urls.map((url) => {
    let file = url;
    if (typeof url === 'string') {
      file = {
        url: url,
        name: url.split('/').pop(),
      };
    }
    return file;
  });

  return (
    <Paper p="sm" withBorder mt="xs">
      <Grid gutter={0} align="center">
        <Grid.Col span={12} mb={4}>
          <Text fw={500}>Selected Files</Text>
        </Grid.Col>
        {files.map((file, index) => (
          <Fragment key={file?.name}>
            <Grid.Col span={10}>
              <Text size="sm">
                {file?.name}
                {file?.size && (
                  <Text span size="sm" ml={4} c="dimmed">
                    {file.size >= SIZE_MB
                      ? `(${(file.size / SIZE_KB / SIZE_KB).toFixed(2)} MB)`
                      : `(${(file.size / SIZE_KB).toFixed(2)} KB)`}
                  </Text>
                )}
              </Text>
            </Grid.Col>
            <Grid.Col span={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CloseButton c="red" onClick={() => handleRemove(index)} />
            </Grid.Col>
            <Grid.Col span={12}>{files.length > 0 && index !== files.length - 1 && <Divider my={4} />}</Grid.Col>
          </Fragment>
        ))}
      </Grid>
    </Paper>
  );
};

const FileUpload = ({ source, type = 'all', multiple = false, hidePreview = false, error, ...props }) => {
  const { getValues, setFieldValue, setFieldError } = useFormContext();

  const values = getValues();
  const url = values[source] || [];

  const urls = Array.isArray(url) ? url : [url].filter(Boolean);
  const showDropzone = (!urls?.length && !multiple) || multiple;
  const showPreview = !!urls?.length && !multiple && !hidePreview;

  const handleRemove = (index) => {
    const filteredUrls = urls.filter((_, i) => i !== index);
    return setFieldValue(source, filteredUrls?.length > 0 ? filteredUrls : null);
  };

  const handleDrop = (files) => {
    setFieldValue(source, [...urls, ...files]);
  };

  const handleReject = (e) => {
    if (!e[0]?.errors?.length) {
      setFieldError(source, 'File upload failed');
      return;
    }

    const errorCode = e[0].errors[0].code;
    let error;

    switch (errorCode) {
      case 'file-invalid-type':
        error = type === 'image' ? 'Must be an image file' : type === 'video' ? 'Must be a video file' : 'Invalid file type';
        break;
      case 'file-too-large':
        {
          const fileSize = (e[0].file.size / SIZE_MB).toFixed(2);
          error = `File is too large (${fileSize}/${(MAX_FILE_SIZE / SIZE_MB).toFixed(2)}MB)`;
        }
        break;
      default:
        error = 'File upload failed';
    }

    setFieldError(source, error);
  };

  return (
    <InputWrapper error={error} {...props}>
      {showPreview && <MediaPreview file={urls[0]} />}
      {showDropzone && (
        <Dropzone
          onDrop={handleDrop}
          onReject={handleReject}
          maxSize={MAX_FILE_SIZE}
          accept={ACCEPTED_FILES[type]}
          multiple={multiple}
          mb="calc(var(--mantine-spacing-xs) / 2)"
          style={{
            borderColor: error && 'var(--mantine-color-red-6)',
          }}
        >
          <Group justify="center" gap="xl" mih={150}>
            <Dropzone.Accept>
              <IconUpload size={52} style={{ color: 'var(--mantine-color-blue-6)' }} stroke={1.5} />
            </Dropzone.Accept>
            <Dropzone.Reject>
              <IconX size={52} style={{ color: 'var(--mantine-color-red-6)' }} stroke={1.5} />
            </Dropzone.Reject>
            <Dropzone.Idle>
              <IconPhoto size={52} style={{ color: 'var(--mantine-color-dimmed)' }} stroke={1.5} />
            </Dropzone.Idle>

            <div>
              <Text size="xl" inline>
                {multiple ? 'Drop files here or click to select' : 'Drop file here or click to select'}
              </Text>
              <Text size="sm" c="dimmed" inline mt="xs">
                {multiple ? 'Attach files, each file should not exceed 5mb' : 'Attach a file, it should not exceed 5mb'}
              </Text>
            </div>
          </Group>
        </Dropzone>
      )}
      <SelectedFiles urls={urls} handleRemove={handleRemove} />
    </InputWrapper>
  );
};

export default FileUpload;
