import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Container, Title } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useForm } from '@mantine/form';

import supabase from '../../config/supabaseClient';
import PageAnchor from '../common/BackAnchor';

import { useUser } from '../../context/SessionContext';
import { PracticeFormProvider } from './PracticeFormContext';
import PracticeStepper from './stepper/PracticeStepper';

const createPractice = async (values, userId) => {
  const { name, description, template, drills, categoriesOrder } = values;

  const { data: practice } = await supabase
    .from('practice')
    .insert({ name, description, template_id: template, user_id: userId })
    .select()
    .single()
    .throwOnError();

  const practiceDrills = categoriesOrder.flatMap((category) => drills[category]).filter(Boolean);

  await supabase
    .from('practice_drill')
    .insert(
      practiceDrills.map((drill, index) => ({
        practice_id: practice.id,
        drill_id: drill.id,
        order: index + 1,
      })),
    )
    .throwOnError();

  return practice;
};

export default function PracticeCreate() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const user = useUser();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      name: '',
      description: '',
      level: '',
      duration: '',
      shooters: 3,
      template: undefined,
      drills: [],
      requiredDrills: null,
    },
    onValuesChange: (values, prev) => {
      if (values?.template !== prev?.template) {
        form.setValues({ drills: [], requiredDrills: null });
      }
    },
    validate: (values) => {
      if (step === 0) {
        return {
          name: !values.name.trim().length && 'Name is required',
        };
      }
      if (step === 1) {
        return {
          duration: !values.duration && 'Duration is required',
          level: !values.level && 'Level is required',
          shooters: values.shooters === null && 'Number of shooters is required',
        };
      }
      if (step === 2) {
        return {
          template: values.template === undefined && 'Template is required',
        };
      }
      if (step === 3) {
        const errors = {};

        const numOfDrills = Object.values(values?.drills).flat()?.length;

        if (numOfDrills === 0) {
          errors.drills = 'Select at least one drill';
        }

        return errors;
      }
    },
  });

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const newPractice = await createPractice(values, user.id);

      queryClient.invalidateQueries('practices');

      notifications.show({
        title: 'Practice created',
        message: `${values.name} has been successfully created`,
        color: 'green',
      });
      form.resetDirty();
      navigate(`/practices/${newPractice.id}`);
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'An error occurred while creating the practice',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  const context = { ...form, step, setStep, loading, setLoading };

  return (
    <>
      <PageAnchor label="Back to Practices" to="/practices" />
      <Container size="sm" p={0} mb={60}>
        <Title order={2} align="center" fw={700} mb="lg">
          Create a Practice
        </Title>
        <PracticeFormProvider form={context}>
          <form id="practice-create-form">
            <PracticeStepper handleSubmit={handleSubmit} />
          </form>
        </PracticeFormProvider>
      </Container>
    </>
  );
}
