import { Group, Title, Box, Divider, Avatar } from '@mantine/core';
import { useLocation } from 'react-router-dom';

import tabs from '../../utils/tabs';

const PageHeader = ({ title, actions = [] }) => {
  const location = useLocation();
  const currentPath = location.pathname.startsWith('/') ? location.pathname.substring(1) : location.pathname;
  const pathSegments = currentPath.split('/');
  const pathResource = pathSegments[0];

  const { icon: Icon, color: iconColor } =
    tabs.find((tab) => (pathResource ? tab.link == `/${pathResource}` : tab.label == title)) || {};

  return (
    <Box component="header" mt="sm">
      <Group justify="space-between">
        <Group gap="xs">
          {Icon && (
            <Avatar size={36} color={`${iconColor}.9`}>
              <Icon size={24} />
            </Avatar>
          )}
          <Title order={1}>{title}</Title>
        </Group>
        <Group gap="md">
          {actions.map((action, index) => (
            <div key={index}>{action}</div>
          ))}
        </Group>
      </Group>
      <Divider my="lg" />
    </Box>
  );
};

export default PageHeader;
