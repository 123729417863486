import { useEffect, useRef, useState } from 'react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import {
  Badge,
  Box,
  Button,
  Center,
  CloseButton,
  Divider,
  Group,
  Loader,
  Modal,
  NumberInput,
  ScrollArea,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useDebouncedState, useDisclosure } from '@mantine/hooks';
import { IconArrowLeft, IconPlus, IconSearch } from '@tabler/icons-react';

import { usePracticeFormContext } from '../PracticeFormContext';
import useMediaQuery from '../../../hooks/useMediaQuery';
import supabase from '../../../config/supabaseClient';

import LevelSelect from '../../common/inputs/LevelSelect';
import CategorySelect from '../../common/inputs/CategorySelect';

import { DrillDetails } from '../../drills/DrillShow';
import { Section } from './StepTwo';

import classes from './DrillSelectModal.module.css';

const DrillList = ({ categoryId, search, selectedDrills, handleClick, handleView }) => {
  const { getValues } = usePracticeFormContext();
  const { shooters, level } = getValues();

  const { data: drills, isPending } = useQuery({
    queryKey: ['drill', { category_id: categoryId, search, shooters, level }],
    queryFn: async () => {
      let query = supabase.from('drill').select('*, category (name)');

      if (categoryId && categoryId !== 'custom') query.eq('category_id', categoryId);
      if (search) query.ilike('name', `%${search}%`);
      if (shooters) query.lte('shooters', shooters);
      if (level) {
        query.lte('level', level);
        query.order('level', { ascending: false });
      }

      return query.throwOnError().then(({ data }) => data);
    },
    placeholderData: keepPreviousData,
  });

  if (isPending)
    return (
      <Center h={150}>
        <Loader />
      </Center>
    );

  if (!drills?.length)
    return (
      <Center h={150} fw={500}>
        No drills found
      </Center>
    );

  return (
    <SimpleGrid type="container" cols={{ base: 1, '425px': 2, '800px': 3, '1000px': 4 }} my="sm">
      {drills.map((drill) => {
        const selectedIndex = selectedDrills.findIndex((d) => d.id === drill.id);
        const selected = selectedIndex !== -1;

        return (
          <Section key={drill?.id} bg="white" style={{ display: 'flex', flexDirection: 'column' }}>
            <Stack pos="relative" gap="xs" mb="md">
              {selected && (
                <Badge size="md" circle pos="absolute" top={0} right={0}>
                  {selectedIndex + 1}
                </Badge>
              )}
              <Group gap="xs" justify="space-between">
                <Text fw={500} pr="xl">
                  {drill?.name}
                </Text>
              </Group>
              <Group gap="xs">
                <Badge variant="outline" size="sm">
                  {drill?.category?.name}
                </Badge>
                <Badge variant="outline" size="sm">
                  {drill?.shooters} shooters
                </Badge>
              </Group>
              <Text size="sm" c="dimmed" lineClamp={2}>
                {drill?.description}
              </Text>
            </Stack>
            <SimpleGrid cols={{ base: 1, sm: 2 }} spacing="xs" mt="auto">
              <Button size="xs" variant="outline" onClick={() => handleView(drill)}>
                View
              </Button>
              <Button size="xs" color={selected ? 'red' : 'sng.6'} onClick={() => handleClick(drill)}>
                {selected ? 'Remove' : 'Add'}
              </Button>
            </SimpleGrid>
          </Section>
        );
      })}
    </SimpleGrid>
  );
};

const FilterToolbar = ({ categoryId, search, setSearch, setCategoryId }) => {
  const { getValues, setValues } = usePracticeFormContext();
  const { template, shooters, level } = getValues();
  const [input, setInput] = useState(search);

  const handleSearch = (e) => {
    setInput(e.target.value);
    setSearch(e.target.value);
  };

  const handleClear = () => {
    setInput('');
    setSearch('');
  };

  return (
    <Group my="sm" gap="xs">
      <TextInput
        label="Search"
        value={input}
        onChange={handleSearch}
        leftSection={<IconSearch size={18} />}
        rightSection={input && <CloseButton variant="transparent" onClick={handleClear} />}
        placeholder="Search drills"
        miw={250}
        flex={1}
        size="md"
      />

      <Group gap="xs" w={{ base: '100%', sm: 'auto' }}>
        <CategorySelect value={categoryId} onChange={setCategoryId} miw={250} flex={2} disabled={template !== null} size="md" />
        <LevelSelect value={level} onChange={(value) => setValues({ level: value })} miw={100} flex={1} size="md" />
        <NumberInput
          label="Shooters"
          value={shooters}
          onChange={(value) => setValues({ shooters: value })}
          min={1}
          max={3}
          miw={100}
          flex={1}
          size="md"
        />
      </Group>
    </Group>
  );
};

const ModalHeader = ({ showBack = false, handleBack }) => {
  if (showBack)
    return (
      <Button size="md" variant="subtle" leftSection={<IconArrowLeft size={18} />} onClick={handleBack} my="xs" mr="auto">
        Back
      </Button>
    );

  return (
    <>
      <Group p="md" justify="space-between">
        <Text size="xl" fw={500}>
          Drill Select
        </Text>
        <Modal.CloseButton />
      </Group>
      <Text px="md" mb="md">
        Select drills from the list below to add to your practice.
      </Text>
    </>
  );
};

const ModalBody = ({ category: defaultCategory, viewingDrill, localSelectedDrills, setViewingDrill, handleSelectDrills }) => {
  const isMobile = useMediaQuery('sm');
  const scrollAreaRef = useRef(null);
  const [categoryId, setCategoryId] = useState(defaultCategory?.id);
  const [search, setSearch] = useDebouncedState('', 300);

  const handleViewDrill = (drill) => {
    setViewingDrill(drill);
    scrollAreaRef.current?.scrollTo({ top: 0 });
  };

  return (
    <Modal.Body bg="gray.0">
      <ScrollArea
        h="100%"
        type={isMobile ? 'scroll' : 'auto'}
        scrollbars="y"
        flex={1}
        px="md"
        style={{ display: isMobile && viewingDrill && 'none' }}
      >
        <FilterToolbar categoryId={categoryId} search={search} setCategoryId={setCategoryId} setSearch={setSearch} />
        <DrillList
          categoryId={categoryId}
          search={search}
          selectedDrills={localSelectedDrills}
          handleClick={handleSelectDrills}
          handleView={handleViewDrill}
        />
      </ScrollArea>
      {viewingDrill && (
        <ScrollArea viewportRef={scrollAreaRef} h="100%" type={isMobile ? 'scroll' : 'auto'} scrollbars="y" flex={1} px="sm">
          {!isMobile ? (
            <Box p="md" my="sm" pos="relative">
              <CloseButton
                size="lg"
                onClick={() => setViewingDrill(null)}
                pos="absolute"
                top={35}
                right={35}
                style={{ zIndex: 1 }}
              />
              <DrillDetails drill={viewingDrill} isActive hideEdit />
            </Box>
          ) : (
            <DrillDetails drill={viewingDrill} isActive hideEdit my="sm" />
          )}
        </ScrollArea>
      )}
    </Modal.Body>
  );
};

const DrillSelectModal = ({ category, required, selectedDrills, handleDrillSelect }) => {
  const isMobile = useMediaQuery('sm');
  const [opened, { open, close }] = useDisclosure(false);
  const [localSelectedDrills, setLocalSelectedDrills] = useState(selectedDrills);
  const [viewingDrill, setViewingDrill] = useState(null);

  const addDisabled =
    (!selectedDrills.length && !localSelectedDrills.length) || (required && localSelectedDrills.length > required);

  useEffect(() => {
    setLocalSelectedDrills(selectedDrills);
  }, [selectedDrills]);

  const handleSelectDrills = (drill) => {
    setLocalSelectedDrills((prevDrills) =>
      prevDrills.some((d) => d.id === drill.id) ? prevDrills.filter((d) => d.id !== drill.id) : [...prevDrills, drill],
    );
  };

  const handleConfirm = () => {
    handleDrillSelect(localSelectedDrills);
    close();
  };

  const handleClose = () => {
    setLocalSelectedDrills(selectedDrills);
    setViewingDrill(null);
    close();
  };

  const isViewingDrillMobile = isMobile && viewingDrill;

  return (
    <>
      <Button variant="default" mih={150} h="100%" w="auto" onClick={open}>
        <IconPlus size={48} color="var(--mantine-primary-color-filled)" />
      </Button>
      <Modal.Root
        opened={opened}
        onClose={handleClose}
        size="auto"
        centered
        fullScreen={isMobile}
        classNames={{ body: classes.modalBody, content: classes.modalContent }}
      >
        <Modal.Overlay />
        <Modal.Content>
          <ModalHeader showBack={isViewingDrillMobile} handleBack={() => setViewingDrill(null)} />
          <Divider />
          <ModalBody
            category={category}
            viewingDrill={viewingDrill}
            localSelectedDrills={localSelectedDrills}
            setViewingDrill={setViewingDrill}
            handleSelectDrills={handleSelectDrills}
          />
          <Divider />
          {!isViewingDrillMobile && (
            <Group p="sm" justify="space-between">
              <Button size="sm" variant="outline" onClick={handleClose}>
                Cancel
              </Button>
              <Button size="sm" onClick={handleConfirm} disabled={addDisabled}>
                Save ({localSelectedDrills.length}
                {required ? `/${required}` : ''})
              </Button>
            </Group>
          )}
        </Modal.Content>
      </Modal.Root>
    </>
  );
};

export default DrillSelectModal;
