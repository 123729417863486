import { useMemo } from 'react';
import { Container, Group } from '@mantine/core';

import PageHeader from '../components/common/PageHeader';
import DataTable from '../components/common/DataTable';
import { AddDurationDialogButton, EditDurationDialogButton } from '../components/duration/DurationDialog';
import { DeleteModalButton } from '../components/common/DeleteModal';

export default function AdminDurations() {
  const columns = useMemo(
    () => [
      {
        accessor: 'name',
      },
      {
        accessor: 'created_at',
        title: 'Created At',
      },
      {
        accessor: 'actions',
        title: '',
        textAlign: 'right',
        render: (record) => (
          <Group justify="right" wrap="no-wrap">
            <EditDurationDialogButton initialValues={record} />
            <DeleteModalButton record={record} />
          </Group>
        ),
      },
    ],
    [],
  );

  return (
    <Container size="xl" p={0}>
      <PageHeader title="Durations" actions={[<AddDurationDialogButton key="create-duration" />]} />
      <DataTable columns={columns} />
    </Container>
  );
}
