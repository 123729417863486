import { useState } from 'react';
import { Button, Group, Modal } from '@mantine/core';
import { useField } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IconMailForward } from '@tabler/icons-react';

import { fetchApi } from '../../api';
import { useSessionContext, useUser } from '../../context/SessionContext';
import EmailInput from '../common/inputs/EmailInput';

export const isNotOwnEmail = (email, userEmail) => (email !== userEmail ? null : 'You cannot share to yourself');

export const isEmail = (email) => (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) ? null : 'Invalid email');

const SharePracticeDialog = ({ opened = false, setOpen, handleShare }) => {
  const user = useUser();
  const field = useField({
    initialValue: '',
    validateOnBlur: true,
    validate: (val) => isEmail(val) || isNotOwnEmail(val, user.email),
  });

  const handleClick = async () => {
    const invalid = await field.validate();
    if (invalid) return;
    handleShare({ email: field.getValue() });
  };

  return (
    <Modal opened={opened} title="Share Practice" onClose={() => setOpen(false)} zIndex={9999}>
      <EmailInput placeholder="email@domain.com" {...field.getInputProps()} />
      <Group justify="space-between" mt="lg">
        <Button variant="outline" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button onClick={handleClick}>Send</Button>
      </Group>
    </Modal>
  );
};

const SharePractice = ({ practice }) => {
  const { session } = useSessionContext();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);

  const mutation = useMutation({
    mutationFn: async ({ email }) => {
      await fetchApi(session, '/share-practice', {
        method: 'POST',
        body: JSON.stringify({ email, practiceId: practice.id }),
      });
      return { email };
    },
    onError: (err, { email }) => {
      const message =
        err.code === 'PRACTICE_ALREADY_SHARED' ? 'Practice already shared with this user' : `Error sharing practice to ${email}`;

      notifications.show({
        title: 'Error',
        message,
        color: 'red',
      });
    },
    onSuccess: ({ email }) => {
      notifications.show({
        title: 'Success',
        message: `Practice shared to ${email}`,
        color: 'green',
      });

      queryClient.invalidateQueries(['shared_practice']);
    },
    onSettled: () => {
      setOpen(false);
    },
  });

  return (
    <>
      <Button
        size="sm"
        leftSection={<IconMailForward size={16} />}
        onClick={() => setOpen(true)}
        variant="outline"
        loading={mutation.isPending}
      >
        Share
      </Button>
      {open && <SharePracticeDialog opened={open} setOpen={setOpen} handleShare={(values) => mutation.mutate(values)} />}
    </>
  );
};

export default SharePractice;
