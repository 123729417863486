import { Navigate, Outlet } from 'react-router-dom';

import { useSubscription, useUserRole, useIsActiveSubscription } from '../../context/SessionContext';
import { ADMIN, SYSTEM_ADMIN } from '../../constants';

const SubscribedRoutes = () => {
  const role = useUserRole();
  const subscription = useSubscription();
  const isActiveSubscription = useIsActiveSubscription();

  if ([SYSTEM_ADMIN, ADMIN].includes(role)) {
    return <Outlet />;
  }

  if (!subscription || !subscription?.product) {
    return <Navigate to="/subscription-plans" replace />;
  }

  if (!isActiveSubscription) {
    return <Navigate to="/my-profile" replace />;
  }

  return <Outlet />;
};

export default SubscribedRoutes;
