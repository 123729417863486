import { useEffect, useState } from 'react';
import { Center, Container, Stack, Stepper, Text } from '@mantine/core';
import { IconBell, IconCircleDashedCheck, IconCrown } from '@tabler/icons-react';

import CustomCard from '../components/common/CustomCard';
import { useSessionContext } from '../context/SessionContext';
import { fetchApi } from '../api';
import useMediaQuery from '../hooks/useMediaQuery';
import { useNavigate } from 'react-router-dom';

const pricingTableId = import.meta.env.VITE_STRIPE_PRICING_TABLE_ID;
const publicStripeKey = import.meta.env.VITE_STRIPE_PUBLIC_KEY;

const Title = () => {
  return (
    <Stack mb="lg">
      <Text size="xl" align="center" fw={700}>
        Choose a plan that fits your needs
      </Text>
      <Text size="md" align="center" c="dimmed">
        Start a risk free 7 day trial with any plan
      </Text>
    </Stack>
  );
};

const PlanInstructions = () => {
  const isMobile = useMediaQuery('lg');

  if (isMobile) return null;
  return (
    <Stack gap="xs">
      <Center>
        <div style={{ width: '75%' }}>
          <Stepper size="sm" active={0} onStepClick={false} allowNextStepsSelect={false} m="xl">
            <Stepper.Step
              icon={<IconCircleDashedCheck />}
              label="Today"
              description={
                <div style={{ maxWidth: '100px', whiteSpace: 'normal' }}>
                  Dive into the app with full access to all the features in your plan
                </div>
              }
            />
            <Stepper.Step
              icon={<IconBell />}
              label="Day 6"
              description={
                <div style={{ maxWidth: '100px', whiteSpace: 'normal' }}>We'll remind you that your trial is ending soon</div>
              }
            />
            <Stepper.Step
              icon={<IconCrown />}
              label="Day 7"
              description={
                <div style={{ maxWidth: '100px', whiteSpace: 'normal' }}>
                  You'll be billed and continue to have access to all features
                </div>
              }
            />
          </Stepper>
        </div>
      </Center>
    </Stack>
  );
};

export default function SubscriptionPlans() {
  const navigate = useNavigate();
  const { session } = useSessionContext();
  const [clientSecret, setClientSecret] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchApi(session, '/customer-session', { method: 'POST' })
      .then(({ clientSecret }) => {
        setClientSecret(clientSecret);
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (session?.subscription?.isShared || session?.subscription?.product) {
      navigate('/');
    }
  }, [navigate, session?.subscription]);

  return (
    <Container size="lg" p={0}>
      <CustomCard mt="lg" isLoading={loading} isError={error}>
        <Title />
        <PlanInstructions />
        {clientSecret && (
          <stripe-pricing-table
            pricing-table-id={pricingTableId}
            publishable-key={publicStripeKey}
            customer-session-client-secret={clientSecret}
          />
        )}
      </CustomCard>
    </Container>
  );
}
