import { Button, Group, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';

import { useIsActiveSubscription, useSubscription, useUser } from '../../context/SessionContext';
import { STRIPE_STATUS_TRIALING } from '../../constants';
import { ManageBilling } from './SubscriptionCard';
import useMediaQuery from '../../hooks/useMediaQuery';
import { isLoggedIn } from '../auth/LoggedInRoutes';

export const ViewPlan = () => {
  const navigate = useNavigate();
  return (
    <Button onClick={() => navigate('/subscription-plans')} size="xs">
      View Plans
    </Button>
  );
};

const TrialEnd = () => {
  const subscription = useSubscription();
  const isMobile = useMediaQuery('xs');

  const daysLeft = formatDistanceToNow(new Date(subscription?.trial_end * 1000));

  return (
    <Group>
      {!isMobile && (
        <Text size="sm" c="dimmed">
          {`Trial ends in ${daysLeft}`}
        </Text>
      )}
    </Group>
  );
};

const SubscribeCTA = () => {
  const user = useUser();
  const subscription = useSubscription();
  const isActiveSubscription = useIsActiveSubscription();

  if (!isLoggedIn(user) || isActiveSubscription) {
    return null;
  }

  if (!subscription || !subscription?.product) {
    return <ViewPlan />;
  }

  if (!subscription?.default_payment_method || subscription?.cancel_at || !isActiveSubscription) {
    return (
      <Group>
        {subscription?.status === STRIPE_STATUS_TRIALING && <TrialEnd />}
        <ManageBilling />
      </Group>
    );
  }

  return null;
};

export default SubscribeCTA;
