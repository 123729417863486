import supabase from '../config/supabaseClient';

import { ADMIN, SYSTEM_ADMIN } from '../constants';

export const signUp = async (email, password, name) => {
  const { data, error } = await supabase.auth.signUp({
    email,
    password,
    options: {
      data: {
        name,
      },
    },
  });

  return { data, error };
};

export const signIn = async (email, password) => {
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password,
  });

  return { data, error };
};

export const signOut = async () => {
  const { error } = await supabase.auth.signOut();

  return { error };
};

export const hasAccess = (type, role) => {
  if (!role && !type) {
    return true;
  }
  if (role === ADMIN && (!type || type === ADMIN)) {
    return true;
  }
  if (role === SYSTEM_ADMIN) {
    return true;
  }
  return false;
};
