import { useState } from 'react';
import { Loader, Select } from '@mantine/core';
import { useUncontrolled } from '@mantine/hooks';
import { useQuery } from '@tanstack/react-query';

import supabase from '../../../config/supabaseClient';

const useDurations = (enabled = true) =>
  useQuery({
    queryKey: ['durations'],
    queryFn: async () => await supabase.from('duration').select().throwOnError(),
    select: ({ data }) => data.map(({ id, name }) => ({ value: String(id), label: name })),
    enabled,
  });

const DurationSelect = ({ value, defaultValue, onChange, defaultEnabled = true, ...props }) => {
  const [enabled, setEnabled] = useState(defaultEnabled);
  const { data, isPending } = useDurations(defaultEnabled);
  const [_value, setValue] = useUncontrolled({ value, defaultValue, onChange });

  return (
    <Select
      value={_value && String(_value)}
      onChange={setValue}
      data={data}
      label="Duration"
      placeholder="Select a duration"
      rightSection={isPending && <Loader size={18} />}
      searchable
      clearable
      onDropdownOpen={() => {
        if (!enabled) {
          setEnabled(true);
        }
      }}
      {...props}
    />
  );
};

export default DurationSelect;
