export const SYSTEM_ADMIN = 'system_admin';
export const ADMIN = 'admin';

export const ROLE_LABELS = {
  [ADMIN]: 'Admin',
  [SYSTEM_ADMIN]: 'System',
};

export const EXPERIENCE_COLOR = {
  Beginner: 'teal',
  Intermediate: 'orange',
  Advanced: 'red',
};

export const LEVEL_COLOR = {
  1: 'teal',
  2: 'orange',
  3: 'red',
};

export const STRIPE_STATUS_ACTIVE = 'active';
export const STRIPE_STATUS_CANCELLED = 'canceled';
export const STRIPE_STATUS_INCOMPLETE = 'incomplete';
export const STRIPE_STATUS_INCOMPLETE_EXPIRED = 'incomplete_expired';
export const STRIPE_STATUS_PAST_DUE = 'past_due';
export const STRIPE_STATUS_TRIALING = 'trialing';
export const STRIPE_STATUS_UNPAID = 'unpaid';
export const STRIPE_STATUS_PAUSED = 'paused';

export const SUBSCRIPTION_STATUS = {
  [STRIPE_STATUS_ACTIVE]: 'Active',
  [STRIPE_STATUS_CANCELLED]: 'Canceled',
  [STRIPE_STATUS_INCOMPLETE]: 'Incomplete',
  [STRIPE_STATUS_INCOMPLETE_EXPIRED]: 'Incomplete Expired',
  [STRIPE_STATUS_PAST_DUE]: 'Past Due',
  [STRIPE_STATUS_TRIALING]: 'Trialing',
  [STRIPE_STATUS_UNPAID]: 'Unpaid',
  [STRIPE_STATUS_PAUSED]: 'Paused',
};
