import { useEffect } from 'react';
import { Link, Outlet, ScrollRestoration, useLocation, useNavigate } from 'react-router-dom';
import { AppShell, Avatar, Burger, Button, Divider, Group, NavLink, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronRight } from '@tabler/icons-react';

import { useSubscription, useUser, useUserRole } from '../context/SessionContext';

import { isLoggedIn } from '../components/auth/LoggedInRoutes';
import SubscribeCTA from '../components/stripe/SubscribeCTA';

import tabs from '../utils/tabs';
import { hasAccess, signOut } from '../utils/auth';
import { ADMIN, ROLE_LABELS, STRIPE_STATUS_ACTIVE, STRIPE_STATUS_TRIALING, SYSTEM_ADMIN } from '../constants';
import useMediaQuery from '../hooks/useMediaQuery';

const HEADER_HEIGHT = 60;

export default function Layout() {
  const [mobileOpened, { toggle: toggleMobile, close: closeMobile }] = useDisclosure();
  const [desktopOpened, { toggle: toggleDesktop, close: closeDesktop }] = useDisclosure(true);
  const user = useUser();
  const role = useUserRole();
  const subscription = useSubscription();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isMobile = useMediaQuery('sm');

  useEffect(() => {
    if (['/login', '/register'].includes(pathname)) {
      closeMobile();
      closeDesktop();
    }
  }, [pathname, closeMobile, closeDesktop]);

  const handleLogout = async () => {
    await signOut();
    navigate('/');
  };

  return (
    <AppShell
      header={{ height: HEADER_HEIGHT }}
      navbar={{
        width: 300,
        breakpoint: 'sm',
        collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
      }}
      padding={['/login', '/register'].includes(pathname) ? 0 : 'sm'}
      bg="rgba(26, 20, 100, 0.05)"
    >
      <AppShell.Header>
        <Group h="100%" px="md">
          <Burger opened={mobileOpened} onClick={toggleMobile} hiddenFrom="sm" size="sm" />
          <Burger opened={desktopOpened} onClick={toggleDesktop} visibleFrom="sm" size="sm" />
          <Group gap="xs" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
            <Avatar size="md" src="/assets/sng-logo.webp" />
            {!isMobile && (
              <Text size="xl" fw={700}>
                SNG PERFORMANCE
              </Text>
            )}
          </Group>
          <Group ml="auto">
            <SubscribeCTA />
          </Group>
        </Group>
      </AppShell.Header>
      <AppShell.Navbar p="md">
        <AppShell.Section grow my="md">
          {tabs.map(({ divider, link, label, icon: Icon, color, protectedRoute, type }) => {
            if (!hasAccess(type, role)) return null;
            return (
              <div key={link}>
                {divider && <Divider my="xs" label={ROLE_LABELS[type]} fw={500} />}
                <NavLink
                  active={pathname === link}
                  component={Link}
                  to={link}
                  disabled={
                    ![SYSTEM_ADMIN, ADMIN].includes(role) &&
                    protectedRoute &&
                    (!isLoggedIn(user) ||
                      !subscription ||
                      ![STRIPE_STATUS_ACTIVE, STRIPE_STATUS_TRIALING].includes(subscription?.status))
                  }
                  label={<Text fw={500}>{label}</Text>}
                  leftSection={
                    <Avatar size={28} color={`${color}.9`}>
                      <Icon size={16} />
                    </Avatar>
                  }
                  onClick={() => mobileOpened && toggleMobile()}
                  style={{ borderRadius: 'var(--mantine-radius-md)' }}
                />
              </div>
            );
          })}
        </AppShell.Section>
        <AppShell.Section>
          <Divider mb="md" />
          {user ? (
            <>
              <NavLink
                active={pathname === '/my-profile'}
                component={Link}
                to="/my-profile"
                label={
                  <Text size="sm" fw={500} truncate>
                    {user?.user_metadata?.name}
                  </Text>
                }
                description={
                  <Text size="xs" truncate>
                    {user?.email}
                  </Text>
                }
                leftSection={<Avatar size="md" name={user?.user_metadata?.name} color="initials" />}
                rightSection={<IconChevronRight size={20} />}
                onClick={() => mobileOpened && toggleMobile()}
                style={{ borderRadius: 'var(--mantine-radius-md)' }}
              />
              <Button mt="sm" size="xs" fullWidth onClick={handleLogout}>
                Logout
              </Button>
            </>
          ) : (
            <Group justify="space-between" mx="xs">
              <Button onClick={() => navigate('/login', { state: { type: 'register' } })}>Get Started</Button>
              <Button variant="outline" onClick={() => navigate('/login')}>
                Login
              </Button>
            </Group>
          )}
        </AppShell.Section>
      </AppShell.Navbar>
      <AppShell.Main>
        <Outlet />
        <ScrollRestoration />
      </AppShell.Main>
    </AppShell>
  );
}
