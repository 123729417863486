import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button, Container, Group, Paper, rem, ScrollArea, SegmentedControl, Stack, Text } from '@mantine/core';
import { IconMail, IconMailForward, IconPlus, IconStarFilled, IconUser } from '@tabler/icons-react';

import { EXPERIENCE_COLOR } from '../constants';
import { useUser } from '../context/SessionContext';

import { DeleteModalButton } from '../components/common/DeleteModal';
import { PracticeOwner } from '../components/practices/PracticeShow';
import PageHeader from '../components/common/PageHeader';
import DataTable from '../components/common/DataTable';

const MY_PRACTICES = 'My Practices';
const SHARED_WITH_YOU = 'Shared With You';
const SHARED_WITH_OTHERS = 'Shared With Others';

const getQueryConfig = (control, user) => {
  const baseSelect = '*, template (name, experience (name), duration (name))';
  const sharedSelect = 'shared_practice!inner(shared_user_id, owner_user_id, shared_email, created_at)';

  let select = baseSelect;
  let filter = null;

  switch (control) {
    case MY_PRACTICES:
      select += `, shared_practice (*)`;
      filter = { eq: ['user_id', user?.id] };
      break;
    case SHARED_WITH_YOU:
      select += `, ${sharedSelect}`;
      filter = { eq: ['shared_practice.shared_user_id', user?.id] };
      break;
    case SHARED_WITH_OTHERS:
      select += `, ${sharedSelect}`;
      filter = { eq: ['shared_practice.owner_user_id', user?.id] };
      break;
    default:
      break;
  }

  return { select, filter };
};

const iconStyles = { width: rem(18), height: rem(18) };

const GroupedLabel = Group.withProps({ justify: 'center', gap: 'xs', wrap: 'no-wrap', px: 'sm' });

const segmentedControlData = [
  {
    value: MY_PRACTICES,
    label: (
      <GroupedLabel>
        <IconUser style={iconStyles} />
        {MY_PRACTICES}
      </GroupedLabel>
    ),
  },
  {
    value: SHARED_WITH_YOU,
    label: (
      <GroupedLabel>
        <IconMail style={iconStyles} />
        {SHARED_WITH_YOU}
      </GroupedLabel>
    ),
  },
  {
    value: SHARED_WITH_OTHERS,
    label: (
      <GroupedLabel>
        <IconMailForward style={iconStyles} />
        {SHARED_WITH_OTHERS}
      </GroupedLabel>
    ),
  },
];

const LevelBadge = ({ level }) => (
  <Badge variant="outline" size="sm" color={EXPERIENCE_COLOR[level]}>
    {level}
  </Badge>
);

const NoResults = () => (
  <Paper withBorder shadow="md" p="md">
    <Stack h={200} justify="center" align="center">
      <Text align="center" style={{ textWrap: 'pretty' }}>
        Looks like you have not created any practices yet.
      </Text>
      <Button component={Link} to="/practices/create">
        Create Practice
      </Button>
    </Stack>
  </Paper>
);

export default function MyPractices() {
  const user = useUser();
  const [control, setControl] = useState(MY_PRACTICES);

  const { select, filter } = getQueryConfig(control, user);

  const canShowNoResults = control === MY_PRACTICES;

  const columns = useMemo(
    () => [
      {
        accessor: 'name',
        render: ({ name, is_favorite, user_id }) => (
          <Group gap="xs" wrap="nowrap">
            {name}
            {is_favorite && user_id === user.id && <IconStarFilled size={16} color="orange" />}
          </Group>
        ),
      },
      {
        accessor: 'description',
        width: 400,
      },
      {
        accessor: 'template.experience.name',
        title: 'Level',
        width: 125,
        render: ({ template }) => (template?.experience?.name ? <LevelBadge level={template.experience.name} /> : '-'),
      },
      {
        accessor: 'template.duration.name',
        title: 'Duration',
        render: ({ template }) => template?.duration?.name || '-',
      },
      {
        accessor: 'template.name',
        title: 'Template',
        render: ({ template }) => template?.name || 'Custom',
      },
      {
        accessor: 'created_at',
        title: 'Created At',
      },
      {
        hidden: control !== SHARED_WITH_YOU,
        accessor: 'user_id',
        title: 'Owner',
        render: (practice) => <PracticeOwner practice={practice} />,
      },
      {
        hidden: control === SHARED_WITH_YOU,
        accessor: 'actions',
        title: '',
        textAlign: 'right',
        render: ({ id, name }) => (
          <Group justify="right" wrap="no-wrap">
            <DeleteModalButton record={{ id, name }} resource="practice" />
          </Group>
        ),
      },
    ],
    [control, user.id],
  );

  return (
    <Container size="xl" p={0}>
      <PageHeader
        title="My Practices"
        actions={[
          <Button key="create-practice" component={Link} to="/practices/create" leftSection={<IconPlus size={18} stroke={3} />}>
            CREATE
          </Button>,
        ]}
      />
      <ScrollArea type="never">
        <SegmentedControl
          data={segmentedControlData}
          value={control}
          onChange={setControl}
          color="sng"
          fullWidth
          mb="xs"
          bg="white"
          bd="1px solid gray.3"
        />
      </ScrollArea>
      <DataTable select={select} filter={filter} columns={columns} enableRowClick noResults={canShowNoResults && NoResults} />
    </Container>
  );
}
