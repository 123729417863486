import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Container, Title } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useForm } from '@mantine/form';

import supabase from '../../config/supabaseClient';
import PageAnchor from '../common/BackAnchor';

import { PracticeFormProvider } from './PracticeFormContext';
import { useUser } from '../../context/SessionContext';
import PracticeStepper from './stepper/PracticeStepper';

const editPractice = async (values, userId) => {
  const { id, name, description, template, drills, categoriesOrder } = values;

  const { data: practice } = await supabase
    .from('practice')
    .update({ name, description, template_id: template, user_id: userId })
    .eq('id', id)
    .select()
    .single()
    .throwOnError();

  await supabase.from('practice_drill').delete().eq('practice_id', id).throwOnError();

  const practiceDrills = categoriesOrder.flatMap((category) => drills[category]).filter(Boolean);

  await supabase
    .from('practice_drill')
    .insert(
      practiceDrills.map((drill, index) => ({
        practice_id: practice.id,
        drill_id: drill.id,
        order: index + 1,
      })),
    )
    .throwOnError();

  return practice;
};

export default function PracticeEdit() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const user = useUser();
  const { id } = useParams();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const practiceId = parseInt(id);

  const { data: practice, isLoading: practiceLoading } = useQuery({
    queryKey: ['practice', practiceId],
    queryFn: () =>
      supabase
        .from('practice')
        .select('*, template (id, name, experience (name), duration (name))')
        .eq('id', practiceId)
        .single()
        .throwOnError()
        .then(({ data }) => data),
  });

  const { data: drills, isLoading: drillsLoading } = useQuery({
    queryKey: ['practiceDrill', practiceId],
    queryFn: () =>
      supabase
        .from('practice_drill')
        .select('*, drill!inner(*, category(*))')
        .eq('practice_id', practiceId)
        .order('order')
        .throwOnError()
        .then(({ data }) => data),
  });

  const { initialize, ...form } = useForm({
    mode: 'uncontrolled',
    initialValues: {
      id: practiceId,
      name: '',
      description: '',
      template: null,
      drills: {},
      requiredDrills: null,
    },
    validate: (values) => {
      if (step === 0) {
        return {
          name: !values.name.trim().length && 'Name is required',
        };
      }
      if (step === 1) {
        const errors = {};
        const numOfDrills = Object.values(values?.drills).flat()?.length;

        if (numOfDrills === 0) {
          errors.drills = 'Select at least one drill';
        }

        return errors;
      }
    },
  });

  useEffect(() => {
    if (practiceLoading || drillsLoading) return;

    initialize({
      ...practice,
      template: practice?.template_id || null,
      drills: drills.reduce((acc, { drill }) => {
        const categoryId = practice.template_id ? drill.category_id : 'custom';
        if (!acc[categoryId]) {
          acc[categoryId] = [];
        }
        acc[categoryId].push(drill);
        return acc;
      }, {}),
    });
  }, [practice, drills, drillsLoading, practiceLoading, initialize]);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const newPractice = await editPractice(values, user.id);

      queryClient.invalidateQueries('practices');

      notifications.show({
        title: 'Practice modified',
        message: `${values.name} has been successfully modified`,
        color: 'green',
      });
      form.resetDirty();
      navigate(`/practices/${newPractice.id}`);
    } catch (error) {
      console.log('error :>> ', error);
      notifications.show({
        title: 'Error',
        message: 'An error occurred while modifying the practice',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  if (practiceLoading || drillsLoading) return null;

  const context = { ...form, step, setStep, loading, setLoading };

  return (
    <>
      <PageAnchor label="Back" to={`/practices/${practiceId}`} />
      <Container size="sm" p={0} mb={60}>
        <Title order={1} align="center" fw={700} mb="lg">
          Editing {practice.name}
        </Title>
        <PracticeFormProvider form={context}>
          <form id="practice-edit-form">
            <PracticeStepper handleSubmit={handleSubmit} />
          </form>
        </PracticeFormProvider>
      </Container>
    </>
  );
}
