import { createContext, useEffect, useState } from 'react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

import supabase from '../../config/supabaseClient';

const INITIAL_FILTERS = {
  level: 3,
  category: null,
  shooters: 3,
  name: '',
};

const useDrills = ({ category, name, shooters, level, page, pageSize }) =>
  useQuery({
    queryKey: ['drill', { category, name, shooters, level, page, pageSize }],
    queryFn: async () => {
      let query = supabase.from('drill').select('*, category (name, group)', { count: 'exact' });

      if (category) query.eq('category_id', category);
      if (name) query.ilike('name', `%${name}%`);
      if (shooters) query.lte('shooters', shooters);
      if (level) {
        query.lte('level', level);
        query.order('level', { ascending: false });
      }

      return query
        .range((page - 1) * pageSize, page * pageSize - 1)
        .throwOnError()
        .then(({ data, count }) => ({ data, count }));
    },
    placeholderData: keepPreviousData,
  });

export const DrillsContext = createContext();

export const DrillsProvider = ({ children, defaultFilters }) => {
  const [filters, setFilters] = useState({ ...INITIAL_FILTERS, ...defaultFilters });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    setFilters({ ...INITIAL_FILTERS, ...defaultFilters });
  }, [defaultFilters]);

  const { data, isPending, error } = useDrills({
    category: filters.category,
    shooters: filters.shooters,
    level: filters.level,
    name: filters.name,
    page,
    pageSize,
  });

  const { data: drills, count } = data || {};

  const hasFilters = Object.keys(filters).some((key) => key !== 'name' && filters[key] !== INITIAL_FILTERS[key]);

  const handleFiltersChange = (key, value, toggle = true) => {
    setFilters((current) => ({ ...current, [key]: toggle && filters[key] === value ? null : value }));
    setPage(1);
  };

  const clearFilters = () => setFilters(INITIAL_FILTERS);

  const value = {
    isPending,
    error,
    drills,
    filters,
    initialFilters: INITIAL_FILTERS,
    totalPages: Math.ceil(count / pageSize),
    hasFilters,
    page,
    pageSize,
    setPage,
    setPageSize,
    handleFiltersChange,
    setFilters,
    clearFilters,
  };

  return <DrillsContext.Provider value={value}>{children}</DrillsContext.Provider>;
};
