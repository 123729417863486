import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Badge,
  Box,
  Button,
  Card,
  CardSection,
  CloseButton,
  Group,
  Modal,
  ScrollArea,
  SimpleGrid,
  Stack,
  Text,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconEye } from '@tabler/icons-react';

import { usePracticeFormContext } from '../PracticeFormContext';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { formatCategory } from '../../../utils/util';

import { fetchTemplateMembers } from './supabase';

import FormStepError from './FormStepError';
import DrillSelectModal from './DrillSelectModal';
import { DrillDetails } from '../../drills/DrillShow';
import { Section } from './StepTwo';

const dedupeBy = (arr, key = 'id') => {
  const map = new Map();
  arr.forEach((item) => {
    if (!map.has(item[key])) {
      map.set(item[key], item);
    }
  });
  return Array.from(map.values());
};

const DrillSlot = ({ order, drill, onRemove }) => {
  const [opened, { open, close }] = useDisclosure();
  const isMobile = useMediaQuery('sm');

  if (!drill) return null;

  return (
    <>
      <Card mih={150}>
        <Stack h="100%" justify="space-between" gap="xs">
          <CardSection px="xs" pt="xs">
            <Group justify="space-between">
              <Badge size="md" variant="default" style={{ flexShrink: 0, alignSelf: 'baseline' }}>
                # {order}
              </Badge>
              <CloseButton onClick={onRemove} c="red" />
            </Group>
          </CardSection>
          <Text size="md" fw={500} align="center" lh={1.25} lineClamp={3}>
            {drill.name}
          </Text>
          <Group gap="xs" justify="center" grow>
            <Button size="xs" onClick={open} leftSection={<IconEye size={18} />}>
              View
            </Button>
          </Group>
        </Stack>
      </Card>
      <Modal
        opened={opened}
        onClose={close}
        scrollAreaComponent={ScrollArea.Autosize}
        title={
          <Text size="xl" fw={500}>
            Drill Preview
          </Text>
        }
        fullScreen={isMobile}
        size="xl"
      >
        <DrillDetails drill={drill} isActive hideEdit my="sm" />
      </Modal>
    </>
  );
};

const CategoryDrills = ({ category, required }) => {
  const categoryId = category?.id;
  const { getValues, setValues, errors } = usePracticeFormContext();
  const { drills } = getValues();
  const [selectedDrills, setSelectedDrills] = useState(drills[categoryId] || []);

  const selected = selectedDrills?.length;
  const isComplete = selected === required;
  const isError = errors['drills'];

  const handleDrillSelect = (newDrills) => {
    setSelectedDrills(() => {
      const updatedDrills = dedupeBy(newDrills).slice(0, required);
      setValues({ drills: { ...drills, [categoryId]: updatedDrills } });
      return newDrills;
    });
  };

  const handleRemoveDrill = (index) => {
    setSelectedDrills((current) => {
      const updatedDrills = current.filter((_, i) => i !== index);
      setValues({
        drills: { ...drills, [categoryId]: drills[categoryId].filter((drillId) => drillId.id !== current[index].id) },
      });
      return updatedDrills;
    });
  };

  return (
    <Box key={categoryId} mb="lg">
      <Group gap="xs" justify="space-between" mb={4}>
        {categoryId === 'custom' ? (
          <Group gap="xs">
            <Text size="md" fw={500}>
              Drills
            </Text>
            {!!selected && (
              <Badge variant="light" size="md">
                {selectedDrills?.length}
              </Badge>
            )}
          </Group>
        ) : (
          <Group gap="xs">
            <Text size="md" fw={500} c={isError && !isComplete ? 'red' : 'dark.9'}>
              {formatCategory(category)}
            </Text>

            <Badge variant="light" size="md" color={isComplete ? 'green.6' : selected ? 'yellow.6' : 'inherit'}>
              {`${selected} / ${required}`}
            </Badge>
          </Group>
        )}
        {selected && (
          <Button size="compact-sm" variant="subtle" onClick={() => handleDrillSelect([])}>
            Clear
          </Button>
        )}
      </Group>
      <Section>
        <SimpleGrid type="container" cols={{ base: 1, '400px': 2, '600px': 3 }} gap="lg">
          {Array.from({ length: required || selectedDrills?.length + 1 }).map((_, index) => {
            const drill = selectedDrills?.[index];
            return drill ? (
              <DrillSlot
                key={`drill-${drill.id}`}
                order={drill?.order || index + 1}
                drill={drill}
                onRemove={() => handleRemoveDrill(index)}
              />
            ) : (
              <DrillSelectModal
                key={`modal-${index}`}
                category={category}
                required={required}
                selectedDrills={selectedDrills}
                handleDrillSelect={handleDrillSelect}
              />
            );
          })}
        </SimpleGrid>
      </Section>
    </Box>
  );
};

export default function StepFour() {
  const { getValues, setValues } = usePracticeFormContext();
  const { template: templateId } = getValues();

  const isCustom = templateId === null;

  const { data: templateMembers, isLoading } = useQuery({
    queryKey: ['templateMember', templateId],
    queryFn: () => fetchTemplateMembers(templateId),
    initialData: [{ category: { id: 'custom' } }],
    enabled: !!templateId,
  });

  useEffect(() => {
    if (isLoading) return;

    const categoriesOrder = templateMembers.map((member) => member.category_id || 'custom');
    const requiredDrills = templateMembers?.reduce((count, member) => member.count + count, 0);

    setValues({ categoriesOrder, requiredDrills });
  }, [templateMembers, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return Array.from({ length: isCustom ? 1 : 3 }).map((_, index) => <Section key={index} h={175} mb="lg" />);
  }

  if (!isCustom && !templateMembers) return null;

  return (
    <>
      <FormStepError type="drills" />
      {templateMembers.map((member) => (
        <CategoryDrills key={member?.id || 'custom'} category={member?.category} required={member?.count} />
      ))}
    </>
  );
}
