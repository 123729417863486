import { useMantineTheme } from "@mantine/core";
import { useMediaQuery as useMantineMediaQuery } from "@mantine/hooks";

const useMediaQuery = (size, initialValue = undefined, getInitialValueInEffect = false) => {
  const { breakpoints } = useMantineTheme();

  const breakpointValue = breakpoints[size] || size;

  return useMantineMediaQuery(
    `(max-width: ${isNaN(breakpointValue) ? breakpointValue : breakpointValue + 'px'})`,
    initialValue,
    { getInitialValueInEffect },
  );
};

export default useMediaQuery;