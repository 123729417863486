import { useState } from 'react';
import { Loader, Select } from '@mantine/core';
import { useUncontrolled } from '@mantine/hooks';
import { useQuery } from '@tanstack/react-query';

import supabase from '../../../config/supabaseClient';

const groupCategories = (categories, disabledValues) => {
  const groups = categories.reduce((acc, category) => {
    const group = category?.group || 'Other';
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push({ value: String(category.id), label: category.name, disabled: disabledValues?.includes(category.id) });
    return acc;
  }, {});

  return Object.entries(groups)
    .map(([group, items]) => ({
      group,
      items,
    }))
    .sort((a, b) => {
      if (a.group === 'Other') return 1;
      if (b.group === 'Other') return -1;
      return a.group.localeCompare(b.group);
    });
};

const optionsFilter = ({ options, search }) => {
  if (!search) return options;

  const searchLower = search.toLowerCase();
  return options
    .map(({ group, items }) => {
      const filteredItems = items.filter((item) => item.label.toLowerCase().includes(searchLower));
      const groupMatch = group.toLowerCase().includes(searchLower);
      return {
        group,
        items: groupMatch ? items : filteredItems,
        groupMatch,
      };
    })
    .filter(({ items }) => items.length > 0);
};

const useCategories = (enabled = true, disabledValues) =>
  useQuery({
    queryKey: ['categories'],
    queryFn: async () => await supabase.from('category').select().throwOnError(),
    select: ({ data }) => groupCategories(data, disabledValues),
    enabled,
  });

const CategorySelect = ({ value, defaultValue, onChange, defaultEnabled = true, disabledValues, ...props }) => {
  const [enabled, setEnabled] = useState(defaultEnabled);
  const { data, isLoading } = useCategories(enabled, disabledValues);
  const [_value, setValue] = useUncontrolled({ value, defaultValue, onChange });

  return (
    <Select
      value={_value && String(_value)}
      onChange={setValue}
      data={data}
      label="Category"
      placeholder="Select a category"
      rightSection={isLoading && <Loader size={18} />}
      searchable
      clearable
      filter={optionsFilter}
      onDropdownOpen={() => {
        if (!enabled) {
          setEnabled(true);
        }
      }}
      {...props}
    />
  );
};

export default CategorySelect;
