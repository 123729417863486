import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import useResource from './useResource';

const useInitialData = (includeState = true) => {
  const { id } = useParams();
  const location = useLocation();
  const queryClient = useQueryClient();
  const resource = useResource();

  const queryKey = useMemo(() => {
    const key = [resource];
    if (includeState && location.state) {
      const { from, ...locationState } = location.state || {}; // eslint-disable-line no-unused-vars
      key.push({ ...locationState });
    }
    return key;
  }, [resource, includeState, location.state]);

  const cachedData = queryClient.getQueryData(queryKey);

  if (Array.isArray(cachedData)) {
    return cachedData.find((data) => data.id == id);
  }

  return cachedData?.data?.find((data) => data.id == id);
};

export default useInitialData;
