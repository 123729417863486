import React, { useContext, useCallback, useEffect, useMemo } from 'react';
import { useLocalStorage } from '@mantine/hooks';
import { jwtDecode } from 'jwt-decode';

import supabase from '../config/supabaseClient';
import { STRIPE_STATUS_ACTIVE, STRIPE_STATUS_TRIALING } from '../constants';

const stripeOrganizationProductId = import.meta.env.VITE_STRIPE_ORGANIZATION_PRODUCT_ID;

const SessionContext = React.createContext(null);

export const useSessionContext = () => useContext(SessionContext) || {};

export const useUser = () => {
  const { session } = useSessionContext();
  return session?.user;
};

export const useUserRole = () => {
  const { session } = useSessionContext();
  return session?.role;
};

export const useSubscription = () => {
  const { session } = useSessionContext();
  return session?.subscription;
};

export const useIsActiveSubscription = () => {
  const subscription = useSubscription();
  return !!subscription?.isShared || [STRIPE_STATUS_ACTIVE, STRIPE_STATUS_TRIALING].includes(subscription?.status);
};

const fetchSubscription = async (userId) => {
  const { data, error } = await supabase.from('stripe').select('*').eq('user_id', userId).maybeSingle();
  if (error) {
    console.error('Error fetching Stripe data:', error);
    return null;
  }

  if (!data) {
    const { data: sharedSubscription, error: sharedSubscriptionError } = await supabase
      .from('shared_stripe')
      .select(`*, stripe (product, status, user_id)`)
      .eq('shared_user_id', userId)
      .eq('stripe.product', stripeOrganizationProductId)
      .in('stripe.status', [STRIPE_STATUS_ACTIVE, STRIPE_STATUS_TRIALING])
      .maybeSingle();

    if (sharedSubscriptionError) {
      console.error('Error fetching shared subscription:', sharedSubscriptionError);
      return null;
    }

    const { stripe } = sharedSubscription || {};
    const { product, status, user_id: ownerUserId } = stripe || {};

    if (!product || !status || !ownerUserId) return null;

    return { product, status, ownerUserId, isShared: true };
  }

  return data;
};

const updateSession = async (session, setSession) => {
  if (session) {
    const jwt = jwtDecode(session?.access_token);
    session.role = jwt.user_role;

    const subscription = await fetchSubscription(session.user.id);
    session.subscription = subscription;
  }
  setSession(session);
};

export const SessionProvider = (props) => {
  const [session, setSession] = useLocalStorage({
    key: 'session',
    defaultValue: null,
    getInitialValueInEffect: false,
  });

  const reloadSubscription = useCallback(async () => {
    if (session) {
      await updateSession(session, setSession);
    }
  }, [session, setSession]);

  useEffect(() => {
    if (!session) {
      supabase.auth.getSession().then(({ data: { session } }) => {
        updateSession(session, setSession);
      });
    }

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      updateSession(session, setSession);
    });

    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => ({
      session,
      reloadSubscription,
    }),
    [reloadSubscription, session],
  );

  return <SessionContext.Provider value={value} {...props} />;
};
