import { Button, Group, Modal } from '@mantine/core';

const ConfirmDialog = ({ title, text, handleContinue, handleCancel, opened = false }) => {
  return (
    <Modal
      opened={opened}
      title={title}
      onClose={handleCancel}
      zIndex={9999}
      styles={{
        title: { fontSize: 18 },
      }}
    >
      {text}
      <Group justify="space-between" mt="lg">
        <Button variant="outline" onClick={handleContinue}>
          Yes
        </Button>
        <Button data-autofocus onClick={handleCancel}>
          No
        </Button>
      </Group>
    </Modal>
  );
};

export default ConfirmDialog;
