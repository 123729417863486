import { Chip, Group, Input, Select } from '@mantine/core';
import { useUncontrolled } from '@mantine/hooks';

const LEVELS = ['1', '2', '3'];

const LevelsChipGroup = ({ data, ...props }) => {
  return (
    <Input.Wrapper label="Level" size="md">
      <Group gap="xs">
        <Chip.Group {...props}>
          {data.map(({ value, label }) => (
            <Chip key={value} value={value} fw={500}>
              {label}
            </Chip>
          ))}
        </Chip.Group>
      </Group>
    </Input.Wrapper>
  );
};

const LevelSelect = ({ value, defaultValue, onChange, chips = false, ...props }) => {
  const [_value, setValue] = useUncontrolled({ value, defaultValue, onChange });

  const data = LEVELS.map((level) => ({ value: level, label: `Level ${level}` }));

  const handleChange = (value) => {
    setValue(value ? parseInt(value) : null);
  };

  if (chips) return <LevelsChipGroup value={_value && String(_value)} onChange={handleChange} data={data} />;

  return (
    <Select value={_value && String(_value)} onChange={handleChange} data={data} label="Level" allowDeselect={false} {...props} />
  );
};

export default LevelSelect;
