import { Alert, Text } from '@mantine/core';

import { usePracticeFormContext } from '../PracticeFormContext';

export default function FormStepError({ type }) {
  const { errors } = usePracticeFormContext();

  if (!type || !errors[type]) return null;

  return (
    <Alert color="red" mb="lg" ta="center">
      <Text c="red" fw={500}>
        {errors[type]}
      </Text>
    </Alert>
  );
}
