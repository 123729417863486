import { Carousel } from '@mantine/carousel';
import { Badge, Card, Group, Image, Text } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';

import supabase from '../../config/supabaseClient';
import CustomCard from '../common/CustomCard';
import classes from './Announcements.module.css';

const AnnouncementCard = ({ announcement = {} }) => {
  const date = format(new Date(announcement.created_at), 'MMM dd, yyyy');

  return (
    <Card className={classes.card}>
      {announcement.image_url && (
        <Card.Section>
          <Image src={announcement.image_url} alt="title" height={180} />
        </Card.Section>
      )}
      <Card.Section className={classes.section} mt="md">
        <Group justify="apart">
          <Text fz="lg" fw={500}>
            {announcement.title}
          </Text>
          <Badge size="sm" variant="light">
            {date}
          </Badge>
        </Group>
        <Text fz="sm" mt="xs">
          {announcement.content}
        </Text>
      </Card.Section>
    </Card>
  );
};

const AnnouncementCarousel = ({ announcements = [] }) => {
  if (!announcements?.length) return null;
  return (
    <Carousel
      slideSize="100%"
      slideGap="md"
      withIndicators
      classNames={{ indicator: classes.indicator, control: classes.control }}
    >
      {announcements?.map((announcement) => (
        <Carousel.Slide key={announcement.id}>
          <AnnouncementCard key={announcement.id} announcement={announcement} />
        </Carousel.Slide>
      ))}
    </Carousel>
  );
};

const Announcements = () => {
  const {
    data: announcements,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['announcement'],
    queryFn: async () =>
      await supabase
        .from('announcement')
        .select('*')
        .order('created_at', { ascending: false })
        .throwOnError()
        .then(({ data }) => data),
  });

  return (
    <CustomCard title={'Announcements'} resource={'announcements'} isLoading={isLoading} isError={isError}>
      <AnnouncementCarousel announcements={announcements} />
    </CustomCard>
  );
};

export default Announcements;
