import { useDisclosure } from '@mantine/hooks';

import { CreateModal, EditModal, OpenAddModalButton, OpenEditModalButton } from '../common/ResourceModal';
import StopPropagation from '../common/StopPropagation';

import ExperienceForm from './ExperienceForm';

export const AddExperienceDialog = (props) => (
  <CreateModal {...props} size="sm">
    <ExperienceForm />
  </CreateModal>
);

export const EditExperienceDialog = (props) => (
  <EditModal {...props} iconOnly size="sm">
    <ExperienceForm />
  </EditModal>
);

export const AddExperienceDialogButton = (props) => {
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <StopPropagation>
      <OpenAddModalButton onClick={open} />
      <AddExperienceDialog opened={opened} onClose={close} {...props} />
    </StopPropagation>
  );
};

export const EditExperienceDialogButton = (props) => {
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <StopPropagation>
      <OpenEditModalButton onClick={open} iconOnly />
      <EditExperienceDialog opened={opened} onClose={close} {...props} />
    </StopPropagation>
  );
};
