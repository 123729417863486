import { useState } from 'react';
import { Avatar, Badge, Button, Center, Container, Group, Stack, Text } from '@mantine/core';
import { IconMail, IconSettingsCog, IconUser, IconUserShield } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';

import supabase from '../config/supabaseClient';
import { ROLE_LABELS } from '../constants';

import { useSubscription, useUser, useUserRole } from '../context/SessionContext';
import CustomCard from '../components/common/CustomCard';
import SubscriptionCard from '../components/stripe/SubscriptionCard';
import ConfirmDialog from '../components/common/ConfirmDialog';
import { OrganizationCard } from '../components/organization/OrganizationCard';
import { signOut } from '../utils/auth';

const stripeOrganizationProductId = import.meta.env.VITE_STRIPE_ORGANIZATION_PRODUCT_ID;

const getIcon = (role) => {
  switch (role) {
    case 'system_admin':
      return IconSettingsCog;
    case 'admin':
      return IconUserShield;
    default:
      return IconUser;
  }
};

const ProfileCard = ({ ...props }) => {
  const user = useUser();
  const role = useUserRole();

  const RoleIcon = getIcon(role);

  return (
    <CustomCard {...props}>
      <Avatar name={user?.user_metadata?.name} color="initials" size={120} radius={120} mx="auto" />
      <Text ta="center" fz="lg" fw={500} mt="md">
        {user?.user_metadata?.name}
      </Text>
      <Center mt="xs">
        <Badge variant="light" miw="fit-content" leftSection={<RoleIcon size="12" />}>
          {ROLE_LABELS[role] || 'user'}
        </Badge>
      </Center>
      <Center mt="xs">
        <Group wrap="nowrap" gap={10} mt={3}>
          <IconMail
            stroke={1.5}
            size="1rem"
            style={{ color: 'light-dark(var(--mantine-color-gray-5), var(--mantine-color-dark-3))' }}
          />
          <Text fz="xs" c="dimmed">
            {user?.email}
          </Text>
        </Group>
      </Center>
    </CustomCard>
  );
};

const DeleteAccountButton = () => {
  const [opened, setOpened] = useState(false);

  const handleDeleteAccount = async () => {
    try {
      await supabase.rpc('delete_user');
      await signOut();
    } catch (error) {
      return notifications.show({
        title: 'Error',
        message: 'Failed to delete account',
        color: 'red',
      });
    }
  };

  return (
    <>
      <Button color="red" size="xs" mt="xs" onClick={() => setOpened(true)}>
        Delete Account
      </Button>
      <ConfirmDialog
        title="Are you sure you want to delete your account?"
        text="This action cannot be undone."
        handleContinue={handleDeleteAccount}
        handleCancel={() => setOpened(false)}
        opened={opened}
      />
    </>
  );
};

const AccountCard = ({ ...props }) => {
  const user = useUser();

  const handleResetPassword = async () => {
    const { error } = await supabase.auth.resetPasswordForEmail(user?.email, {
      redirectTo: `${import.meta.env.VITE_APP_URL}/reset-password`,
    });

    if (error) {
      notifications.show({
        title: 'Error',
        message: `Failed to send password reset email to ${user?.email}`,
        color: 'red',
      });
    } else {
      notifications.show({
        title: 'Password reset sent',
        message: `Password reset email has been sent to ${user?.email}`,
        color: 'green',
      });
    }
  };

  return (
    <CustomCard title="Account" {...props}>
      <Text>{user.email}</Text>
      <br />
      <Button size="xs" onClick={handleResetPassword}>
        Reset Password
      </Button>
      <DeleteAccountButton />
    </CustomCard>
  );
};

export default function MyProfile() {
  const { product, isShared } = useSubscription() || {};

  const canShowOrganization = product === stripeOrganizationProductId && !isShared;

  return (
    <Container size="xs">
      <Stack gap="md">
        <ProfileCard />
        <AccountCard />
        <SubscriptionCard />
        {canShowOrganization && <OrganizationCard />}
      </Stack>
    </Container>
  );
}
