import { useLocation } from 'react-router-dom';

const resources = {
  practices: 'practice',
  drills: 'drill',
  'admin-announcements': 'announcement',
  'admin-categories': 'category',
  'admin-drills': 'drill',
  'admin-durations': 'duration',
  'admin-experiences': 'experience',
  'admin-templates': 'template',
};

const useResource = (providedResource) => {
  const { pathname } = useLocation();

  if (providedResource) return providedResource;

  const resource = pathname?.split('/')[1];
  return resources?.[resource];
};

export default useResource;
