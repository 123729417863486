import { Anchor, Group, Text } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconArrowLeft } from '@tabler/icons-react';

const DEFAULT_LABEL = 'Back';

const PageAnchor = ({ label = DEFAULT_LABEL, to }) => {
  const location = useLocation();
  const prevLocation = location.state?.from || to;
  const backLabel = location.state?.from ? DEFAULT_LABEL : label;

  return (
    <Anchor component={Link} to={prevLocation} underline={false} style={{ display: 'block', maxWidth: 'fit-content' }}>
      <Group gap={4} mt="xs" mb="lg">
        <IconArrowLeft size={18} />
        <Text>{backLabel}</Text>
      </Group>
    </Anchor>
  );
};

export default PageAnchor;
