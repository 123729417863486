import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Box, Button, Group, Stepper, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import BlockerModal from '../../common/BlockerModal';
import ConfirmDialog from '../../common/ConfirmDialog';
import { usePracticeFormContext } from '../PracticeFormContext';

import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';

import { fetchDurations, fetchExperiences, fetchTemplateMembers, fetchTemplates } from './supabase';

import classes from './Stepper.module.css';

const getSteps = (practiceId) =>
  [
    {
      label: 'Lets get started',
      description: 'Pick a name for your practice and provide a brief description.',
      component: StepOne,
      prefetchData: async () => null, // No prefetch needed for first step
    },
    {
      label: 'Requirements',
      description: 'Select your experience level, duration preference, and number of available shooters.',
      component: StepTwo,
      prefetchData: async (queryClient) => {
        return Promise.all([
          queryClient.prefetchQuery({
            queryKey: ['experience'],
            queryFn: fetchExperiences,
          }),
          queryClient.prefetchQuery({
            queryKey: ['duration'],
            queryFn: fetchDurations,
          }),
        ]);
      },
      hidden: !!practiceId,
    },
    {
      label: 'Choose a Template',
      description: 'Select a template that matches your needs.',
      component: StepThree,
      prefetchData: async (queryClient, formValues) => {
        const { level, duration } = formValues;
        return queryClient.prefetchQuery({
          queryKey: ['template', { level, duration }],
          queryFn: () => fetchTemplates(level, duration),
        });
      },
      hidden: !!practiceId,
    },
    {
      label: 'Select your Drills',
      description: 'Choose the drills for your practice session.',
      component: StepFour,
      prefetchData: async (queryClient, formValues) => {
        const { template: templateId } = formValues;

        if (!templateId) return;

        return queryClient.prefetchQuery({
          queryKey: ['templateMember', templateId],
          queryFn: () => fetchTemplateMembers(templateId),
        });
      },
    },
  ].filter(({ hidden }) => !hidden);

const StepperActions = ({ practiceId, steps, handleNextStep }) => {
  const { step, setStep, loading } = usePracticeFormContext();
  const navigate = useNavigate();

  const totalSteps = steps.length - 1;

  const handlePrevStep = () => {
    if (step === 0) return navigate(-1);
    setStep((current) => (current > 0 ? current - 1 : current));
  };

  return (
    <Group className={classes.actionBar} justify="space-between" mt="xl">
      <Button variant="default" onClick={handlePrevStep}>
        {step === 0 ? 'Cancel' : 'Back'}
      </Button>
      <Button onClick={() => handleNextStep(step + 1)} loading={loading}>
        {step === totalSteps ? (practiceId ? 'Save' : 'Create') : 'Next'}
      </Button>
    </Group>
  );
};

export default function PracticeStepper({ handleSubmit }) {
  const { step, setStep, loading, setLoading, validate, getValues, isDirty } = usePracticeFormContext();
  const queryClient = useQueryClient();
  const [confirmDialogOpened, setConfirmDialogOpened] = useState(false);

  const practiceId = getValues()?.id;
  const steps = getSteps(practiceId);

  const handleStep = async (nextStep) => {
    // Allow moving backwards without validation
    if (nextStep < step) {
      setStep(nextStep);
      return;
    }

    // Validate current step before proceeding
    if (validate().hasErrors) {
      const { id: practiceId } = getValues();
      document.getElementById(`practice-${practiceId ? 'edit' : 'create'}-form`)?.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    // Handle final step submission
    if (step === steps.length - 1) {
      const { drills, requiredDrills } = getValues();

      const numOfDrills = Object.values(drills)?.flat()?.length;
      const meetsRequiredDrills = !isNaN(requiredDrills) ? numOfDrills === requiredDrills : true;

      if (numOfDrills && !meetsRequiredDrills) {
        setConfirmDialogOpened(true);
        return;
      }

      await handleSubmit(getValues());
      return;
    }

    // Prefetch data for next step
    try {
      setLoading(true);
      await steps[nextStep].prefetchData(queryClient, getValues());
      setStep(nextStep);
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to load next step data',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box pb="md">
      <Stepper size="xs" active={step} onStepClick={handleStep} classNames={{ stepIcon: classes.stepIcon }}>
        {steps.map(({ label, description, component: Component }, index) => (
          <Stepper.Step key={label} loading={loading && step === index}>
            <Text size="xl" align="center" fw={700} mt="xl">
              {label}
            </Text>
            <Text align="center" c="dimmed" size="md" mb="xl">
              {description}
            </Text>
            <Component />
          </Stepper.Step>
        ))}
      </Stepper>
      <StepperActions practiceId={practiceId} steps={steps} handleNextStep={handleStep} />
      <BlockerModal shouldPrompt={isDirty} />
      <ConfirmDialog
        title="Incomplete Practice"
        text="You have not selected all recommended drills for this template. Are you sure you want to continue?"
        opened={confirmDialogOpened}
        handleCancel={() => setConfirmDialogOpened(false)}
        handleContinue={() => handleSubmit(getValues())}
      />
    </Box>
  );
}
