import { Badge, createTheme } from '@mantine/core';

const theme = createTheme({
  fontFamily: 'Poppins, sans-serif',
  headings: {
    fontFamily: 'Poppins, sans-serif',
  },
  primaryColor: 'sng',
  primaryShade: { light: 6, dark: 8 },
  colors: {
    sng: [
      '#e6e5f2', // Lightest shade
      '#b3b1d9',
      '#807ec0',
      '#4d4aa7',
      '#1a168e',
      '#171259',
      '#1A1464', // SNG primary color
      '#14104e',
      '#110e43',
      '#0e0b38', // Darkest shade
    ],
    red: ['#D19999', '#C58080', '#B96666', '#AE4D4D', '#A23333', '#971A1A', '#8B0000', '#7D0000', '#6F0000', '#610000'],
  },
  defaultRadius: 'md',

  components: {
    Badge: Badge.extend({
      defaultProps: {
        tt: 'none',
      },
    }),
  },
});

export default theme;
