import { TextInput } from '@mantine/core';

import Form from '../common/Form';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Name is required';
  }

  return errors;
};

const CategoryForm = (props) => {
  return (
    <Form validate={validate} {...props}>
      <TextInput source="name" label="Name" placeholder="Enter a name" size="md" mb="md" data-autofocus />
      <TextInput source="group" label="Group" placeholder="Enter a group name" size="md" mb="md" />
    </Form>
  );
};

export default CategoryForm;
