import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Alert,
  AspectRatio,
  Badge,
  Card as MantineCard,
  Container,
  Group,
  SimpleGrid,
  Stack,
  Text,
  Title,
  ListItem,
  List,
  Box,
} from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import Markdown from 'markdown-to-jsx';
import {
  IconAntennaBars5,
  IconClockHour3,
  IconList,
  IconListCheck,
  IconListNumbers,
  IconSettingsAutomation,
  IconUsers,
} from '@tabler/icons-react';

import supabase from '../../config/supabaseClient';
import PageAnchor from '../common/BackAnchor';
import CustomReactPlayer from '../common/CustomReactPlayer';
import useInitialData from '../../hooks/useInitialData';
import { useUserRole } from '../../context/SessionContext';

import { ADMIN, LEVEL_COLOR } from '../../constants';
import { formatCategory, getMantineColor } from '../../utils/util';
import { hasAccess } from '../../utils/auth';

import { EditDrillDialogButton } from './DrillDialog';

const markdownOptions = {
  overrides: {
    ul: {
      component: List,
    },
    ol: {
      component: List,
    },
    li: {
      component: ListItem,
    },
    p: {
      props: { style: { marginTop: 0, color: 'var(--mantine-color-dimmed)' } },
    },
    span: {
      props: { style: { color: 'var(--mantine-color-dimmed)' } },
    },
  },
};

const Card = MantineCard.withProps({ shadow: 'sm', withBorder: true });

const DrillVideo = ({ url, isActive }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (!isActive) setIsPlaying(false);
  }, [isActive]);

  return (
    <AspectRatio ratio={16 / 9}>
      <CustomReactPlayer url={url} playing={isPlaying} onPlay={() => setIsPlaying(true)} onPause={() => setIsPlaying(false)} />
    </AspectRatio>
  );
};

export const DrillBadges = ({ drill, size = 'md', ...props }) => (
  <Group gap="sm" {...props}>
    <Badge variant="outline" size={size} leftSection={<IconAntennaBars5 size={12} />} color={LEVEL_COLOR[drill.level]}>
      Level {drill.level}
    </Badge>
    <Badge variant="outline" size={size} leftSection={<IconUsers size={12} />}>
      {`${drill.shooters} ${drill.shooters > 1 ? 'shooters' : 'shooter'}`}
    </Badge>
    <Badge variant="default" size={size} leftSection={<IconList size={12} />}>
      {formatCategory(drill.category)}
    </Badge>
  </Group>
);

export const DrillDetails = ({ drill, isActive, hideEdit = false, ...props }) => {
  const role = useUserRole();

  const canEditDrill = hasAccess(ADMIN, role) && !hideEdit;

  if (!drill) return null;

  return (
    <Stack gap="lg" {...props}>
      <Card>
        <Stack gap="sm" pos="relative">
          <Title order={2} pr="xl">
            {drill?.name}
          </Title>
          {canEditDrill && (
            <Box pos="absolute" top={0} right={0}>
              <EditDrillDialogButton initialValues={drill} resource="drill" />
            </Box>
          )}
          <DrillBadges drill={drill} />
          <Text>{drill?.description}</Text>
        </Stack>
      </Card>

      <DrillVideo url={drill?.video} isActive={isActive} />

      <SimpleGrid type="container" cols={{ base: 1, '450px': 2 }}>
        <Card>
          <Group gap="sm" mb="sm">
            <IconSettingsAutomation size={22} color={getMantineColor('sng')} />
            <Text size="xl" fw={500}>
              Requirements
            </Text>
          </Group>
          <Group>
            {!drill?.requirements?.length ? (
              <Text c="dimmed">None</Text>
            ) : (
              drill.requirements.map((requirement) => (
                <Badge key={requirement} variant="outline" size="md">
                  {requirement}
                </Badge>
              ))
            )}
          </Group>
        </Card>
        <Card>
          <Group gap="sm" mb="sm">
            <IconClockHour3 size={22} color={getMantineColor('sng')} />
            <Text size="xl" fw={500}>
              Reps
            </Text>
          </Group>
          <Text c="dimmed">{drill?.reps}</Text>
        </Card>
      </SimpleGrid>
      <Card>
        <Group gap="sm" mb="sm">
          <IconListNumbers size={22} color={getMantineColor('sng')} />
          <Text size="xl" fw={500}>
            Setup
          </Text>
        </Group>
        <Markdown options={markdownOptions}>{drill?.setup}</Markdown>
      </Card>

      <Card>
        <Group gap="sm" mb="sm">
          <IconListCheck size={22} color={getMantineColor('sng')} />
          <Text size="xl" fw={500}>
            Instructions
          </Text>
        </Group>
        <Markdown options={markdownOptions}>{drill?.instruction}</Markdown>
      </Card>
      {drill?.coaching_points && (
        <Alert title="Coaching Points" color="yellow.6" bd="1px solid yellow">
          <Markdown>{drill?.coaching_points}</Markdown>
        </Alert>
      )}
    </Stack>
  );
};

export default function DrillShow() {
  const { id } = useParams();
  const initialData = useInitialData();

  const { data: drill, isPending } = useQuery({
    queryKey: ['drill', id],
    queryFn: () =>
      supabase
        .from('drill')
        .select('*, category (name, group)')
        .eq('id', id)
        .single()
        .throwOnError()
        .then(({ data }) => data),
    initialData,
  });

  if (isPending) return <div>Loading...</div>;

  return (
    <>
      <PageAnchor label="Back to Drills" to="/drills" />
      <Container size="md" p={0} mb="xl">
        <DrillDetails drill={drill} />
      </Container>
    </>
  );
}
