import { useBlocker } from 'react-router-dom';

import ConfirmDialog from './ConfirmDialog';

const BlockerModal = ({ shouldPrompt }) => {
  let blocker = useBlocker(() => {
    if (!shouldPrompt()) return false;
    return true;
  });

  const handleCancel = () => blocker.reset();
  const handleContinue = () => blocker.proceed();

  return (
    <ConfirmDialog
      title="Are you sure you want to leave?"
      text="All progress will be lost."
      handleContinue={handleContinue}
      handleCancel={handleCancel}
      opened={blocker.state === 'blocked'}
    />
  );
};

export default BlockerModal;
