import { useQuery } from '@tanstack/react-query';
import { Divider, Group, Paper, SimpleGrid, Text, UnstyledButton } from '@mantine/core';

import { usePracticeFormContext } from '../PracticeFormContext';
import { fetchTemplates } from './supabase';

import { Section } from './StepTwo';
import FormStepError from './FormStepError';

import classes from './Stepper.module.css';

const TemplateCard = ({ template, selected, handleSelect, ...props }) => (
  <Paper
    className={classes.Paper}
    component={UnstyledButton}
    ta="center"
    py="md"
    px="lg"
    shadow="sm"
    onClick={() => handleSelect(template.id)}
    mod={{ selected }}
    {...props}
  />
);

export default function StepThree() {
  const { getValues, setValues } = usePracticeFormContext();
  const { template: selectedTemplate, level, duration } = getValues();

  const { data: templates, isPending } = useQuery({
    queryKey: ['template', { level, duration }],
    queryFn: () => fetchTemplates(level, duration),
  });

  const handleSelect = (template) => {
    if (selectedTemplate === template) {
      setValues({ template: undefined });
    } else {
      setValues({ template });
    }
  };

  if (isPending) return null;

  return (
    <>
      <FormStepError type="template" />
      <Section>
        <SimpleGrid cols={1}>
          {templates.map((template) => (
            <TemplateCard
              key={template.name}
              template={template}
              selected={selectedTemplate == template.id}
              handleSelect={handleSelect}
            >
              <Text size="xl" fw={700}>
                {template.name}
              </Text>
              <Text c="dimmed" ta="left" mb={4}>
                {template.description || 'Lorum ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec dui eget dolor'}
              </Text>
              <Group justify="space-between" mt="xs" mb={4}>
                <Text fw={500}>Category</Text>
                <Text fw={500}>#</Text>
              </Group>
              {template.template_member
                .sort((a, b) => a.order - b.order)
                .map((member) => (
                  <Group key={member.id} gap="xs" justify="space-between" w="100%">
                    <Text>{member.category.name}</Text>
                    <Text c="dimmed" size="sm">
                      {member.category.group ? ` (${member.category.group})` : ''}
                    </Text>
                    <Divider flex={1} variant="dashed" />
                    <Text>{member.count}</Text>
                  </Group>
                ))}
            </TemplateCard>
          ))}
          <TemplateCard mih={165} onClick={() => handleSelect(null)} mod={{ selected: selectedTemplate === null }}>
            <Text size="xl" fw={700}>
              Custom
            </Text>
            <Text size="md" c="dimmed">
              Dont need a template? Select any drills you want to add to your practice.
            </Text>
          </TemplateCard>
        </SimpleGrid>
      </Section>
    </>
  );
}
