import React, { Children, cloneElement, useContext } from 'react';
import { Button, Group } from '@mantine/core';
import { useForm } from '@mantine/form';

const omit = (obj, keys) => {
  return Object.keys(obj).reduce((result, key) => {
    if (!keys.includes(key)) {
      result[key] = obj[key];
    }
    return result;
  }, {});
};

const FormContext = React.createContext(null);

export const useFormContext = () => useContext(FormContext) || {};

const DefaultToolbar = ({ handleClose, loading, submitLabel = 'Submit', cancelLabel = 'Cancel', disabled = false }) => {
  return (
    <Group mt="xl" justify="space-between">
      <Button variant="outline" onClick={handleClose}>
        {cancelLabel}
      </Button>
      <Button type="submit" loading={loading} disabled={disabled}>
        {submitLabel}
      </Button>
    </Group>
  );
};

const applyPropsToChildren = (children, form) => {
  return Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.props.source) {
        return cloneElement(child, {
          ...child.props,
          ...form.getInputProps(child.props.source),
          key: form.key(child.props.source),
        });
      } else if (child.props.children) {
        return cloneElement(child, {
          ...child.props,
          children: applyPropsToChildren(child.props.children, form),
        });
      }
    }
    return child;
  });
};

export default function Form({
  initialValues,
  transformValues,
  validate,
  onSubmit,
  onClose,
  Toolbar = DefaultToolbar,
  toolbarProps,
  children,
}) {
  const form = useForm({
    initialValues,
    validate,
    transformValues,
  });

  const handleClose = () => {
    form.reset();
    onClose();
  };

  const handleSubmit = (values) => {
    const valuesToSave = omit(values, ['created_at']);
    onSubmit(valuesToSave);
  };

  return (
    <FormContext.Provider value={{ ...form, onSubmit: form.onSubmit(handleSubmit) }}>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        {applyPropsToChildren(children, form)}
        <Toolbar {...toolbarProps} handleClose={handleClose} />
      </form>
    </FormContext.Provider>
  );
}
