import { useQuery } from '@tanstack/react-query';

import supabase from '../config/supabaseClient';

export const useUserInfo = (userId) =>
  useQuery({
    queryKey: ['userInfo', userId],
    queryFn: async () =>
      await supabase
        .from('user_info')
        .select('*')
        .eq('user_id', userId)
        .single()
        .throwOnError()
        .then(({ data }) => data),
    enabled: !!userId,
  });
