import { Card as MantineCard, Text, Box } from '@mantine/core';
import { IconExclamationCircle } from '@tabler/icons-react';

import CustomLoader from './CustomLoader';

const Card = MantineCard.withProps({ shadow: 'sm', p: 'md', withBorder: true });

const Body = ({ children, isLoading, isError }) => {
  if (isError || isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBlock: 24 }}>
        {isError ? <IconExclamationCircle size={24} color="orangered" /> : <CustomLoader size={24} />}
      </div>
    );
  }
  return children;
};

const CustomCard = ({ children, title, height, isLoading = false, isError, isOutline = true, ...props }) => {
  const Outline = isOutline ? Card : Box;

  return (
    <Outline style={{ height }} {...props}>
      <Text size="xl" fw={700} mb="xs">
        {title}
      </Text>
      <Body isLoading={isLoading} isError={isError}>
        {children}
      </Body>
    </Outline>
  );
};

export default CustomCard;
