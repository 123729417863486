import { useDisclosure } from '@mantine/hooks';

import { CreateModal, EditModal, OpenAddModalButton, OpenEditModalButton } from '../common/ResourceModal';
import StopPropagation from '../common/StopPropagation';
import TemplateMemberForm from './TemplateMemberForm';

export const AddTemplateMemberDialog = ({ disabledValues, ...props }) => (
  <CreateModal size="md" {...props}>
    <TemplateMemberForm disabledValues={disabledValues} />
  </CreateModal>
);

export const EditTemplateMemberDialog = ({ disabledValues, ...props }) => (
  <EditModal size="md" {...props}>
    <TemplateMemberForm disabledValues={disabledValues} />
  </EditModal>
);

export const AddTemplateMemberDialogButton = (props) => {
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <StopPropagation>
      <OpenAddModalButton onClick={open} openLabel="Add Category" size="xs" />
      <AddTemplateMemberDialog opened={opened} onClose={close} {...props} />
    </StopPropagation>
  );
};

export const EditTemplateMemberDialogButton = (props) => {
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <StopPropagation>
      <OpenEditModalButton onClick={open} iconOnly size="xs" />
      <EditTemplateMemberDialog opened={opened} onClose={close} {...props} />
    </StopPropagation>
  );
};
