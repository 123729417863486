import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ActionIcon, Button, Group, Menu, Modal, Text } from '@mantine/core';
import { upperFirst, useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconTrash } from '@tabler/icons-react';

import supabase from '../../config/supabaseClient';
import useResource from '../../hooks/useResource';

import StopPropagation from './StopPropagation';

const formatResource = (resource, uppercase = true) =>
  resource
    .split('_')
    .map((word) => (uppercase ? upperFirst(word) : word))
    .join(' ');

const DeleteModal = ({ opened, onClose, record, resource: providedResource, deleteFn, redirect = null }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const resource = useResource(providedResource);

  const mutation = useMutation({
    mutationFn: async (id) => {
      if (deleteFn) {
        return await deleteFn(record, resource);
      }
      await supabase.from(resource).delete().eq('id', id).throwOnError();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(resource);

      notifications.show({
        title: `${formatResource(resource)} deleted`,
        message: `${formatResource(resource)} has been successfully deleted`,
        color: 'green',
      });

      if (redirect) {
        navigate(redirect);
      }

      onClose();
    },
    onError: () => {
      notifications.show({
        title: 'Error',
        message: `An error occurred while deleting the ${resource}`,
        color: 'red',
      });
    },
  });

  const handleDelete = () => {
    mutation.mutate(record.id);
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={
        <Text size="lg" fw={500}>
          Delete {formatResource(resource)}
        </Text>
      }
      centered
    >
      Are you sure you want to delete this {formatResource(resource, false)}?
      <Group mt="lg" justify="space-between">
        <Button variant="outline" onClick={onClose} data-autofocus>
          Cancel
        </Button>
        <Button color="red" onClick={handleDelete} loading={mutation.isPending}>
          Delete
        </Button>
      </Group>
    </Modal>
  );
};

export const DeleteModalButton = (props) => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <StopPropagation>
      <ActionIcon color="red" variant="subtle" onClick={open}>
        <IconTrash />
      </ActionIcon>
      <DeleteModal opened={opened} onClose={close} {...props} />
    </StopPropagation>
  );
};

export const DeleteModalMenuItem = (props) => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Menu.Item leftSection={<IconTrash size={18} />} color="red" onClick={open}>
        <Text size="md">Delete Practice</Text>
      </Menu.Item>
      <DeleteModal opened={opened} onClose={close} {...props} />
    </>
  );
};
