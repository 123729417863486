import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ActionIcon } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconStar, IconStarFilled } from '@tabler/icons-react';

import supabase from '../../config/supabaseClient';

const FavouritePracticeButton = ({ practice }) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async () => {
      return supabase
        .from('practice')
        .update({ is_favorite: !practice.is_favorite })
        .eq('id', practice.id)
        .throwOnError()
        .then(({ data }) => data);
    },
    onMutate: async () => {
      await queryClient.cancelQueries(['practice', practice.id]);
      const previousPractice = queryClient.getQueryData(['practice', practice.id]);

      queryClient.setQueryData(['practice', practice.id], {
        ...previousPractice,
        is_favorite: !practice.is_favorite,
      });

      notifications.show({
        title: practice.is_favorite ? 'Unfavorited' : 'Favorited',
        message: practice.is_favorite ? 'Removed practice from favorites' : 'Added practice to favorites',
        color: 'transparent',
        icon: practice.is_favorite ? <IconStar color="gray" /> : <IconStarFilled color="orange" />,
        autoClose: 2000,
      });

      return { previousPractice };
    },
    onError: (err, _, context) => {
      queryClient.setQueryData(['practice', practice.id], context.previousPractice);
      notifications.show({
        title: 'Error',
        message: 'An error occurred while updating the practice',
        color: 'red',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['practice']);
    },
  });

  const Icon = practice.is_favorite ? IconStarFilled : IconStar;

  return (
    <ActionIcon
      size="md"
      variant="subtle"
      color={practice.is_favorite ? 'orange' : 'gray'}
      onClick={() => mutation.mutate()}
      title={practice.is_favorite ? 'Remove from favorites' : 'Add to favorites'}
    >
      <Icon size={20} />
    </ActionIcon>
  );
};

export default FavouritePracticeButton;
