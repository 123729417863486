import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Badge, Button, Card, Group, Text } from '@mantine/core';
import { format } from 'date-fns';

import { useSessionContext, useSubscription } from '../../context/SessionContext';
import { useUserInfo } from '../../hooks/useUserInfo';
import { STRIPE_STATUS_ACTIVE, STRIPE_STATUS_PAUSED, STRIPE_STATUS_TRIALING, SUBSCRIPTION_STATUS } from '../../constants';
import { fetchApi } from '../../api';

import CustomCard from '../common/CustomCard';
import { ViewPlan } from './SubscribeCTA';

const useStripeProduct = () => {
  const { session } = useSessionContext();
  const { product: productId } = useSubscription() || {};
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!productId) return;

    const fetchProduct = async () => {
      setLoading(true);
      const data = await fetchApi(session, `/product?productId=${productId}`);
      setProduct(data);
      setLoading(false);
    };

    fetchProduct();
  }, [productId, session]);

  return { product, loading };
};

export const ManageBilling = ({ text = 'Manage Billing', ...props }) => {
  const { session } = useSessionContext();
  const [loading, setLoading] = useState(false);

  const handleManageBilling = async () => {
    setLoading(true);
    try {
      const data = await fetchApi(session, '/manage-billing');
      if (data?.url) {
        window.location.href = data.url;
      }
    } catch (error) {
      console.error('Error managing billing:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button onClick={handleManageBilling} size="xs" loading={loading} {...props}>
      {text}
    </Button>
  );
};

const getBadgeColour = (status) => {
  switch (status) {
    case STRIPE_STATUS_ACTIVE:
      return 'green';
    case STRIPE_STATUS_TRIALING:
      return 'blue';
    case STRIPE_STATUS_PAUSED:
      return 'yellow';
    default:
      return 'red';
  }
};

const EndingDetails = () => {
  const subscription = useSubscription();

  const { trial_end: trialEnd, cancel_at: cancelAt, default_payment_method: paymentMethod } = subscription || {};

  let endAtText;
  let actionText;

  if (trialEnd && subscription?.status === STRIPE_STATUS_TRIALING) {
    endAtText = `Trial ends ${format(new Date(trialEnd * 1000), 'MMMM dd, yyyy')}.`;
    actionText = !paymentMethod ? 'Payment method required.' : 'Your subscription will start automatically.';
  }

  if (cancelAt) {
    endAtText = `Cancels ${format(new Date(cancelAt * 1000), 'MMMM dd, yyyy')}.`;
    actionText = '';
  }

  return (
    <>
      <Text size="sm" c="dimmed">
        {endAtText || 'Your subscription will renew automatically.'}
      </Text>
      <Text size="sm" c="dimmed">
        {actionText}
      </Text>
    </>
  );
};

const OwnerDetails = () => {
  const subscription = useSubscription();
  const { data: userInfo } = useUserInfo(subscription?.ownerUserId);

  if (!userInfo) return null;
  return (
    <Text size="sm" c="dimmed">
      {`Invited by ${userInfo?.name} (${userInfo?.email})`}
    </Text>
  );
};

const ProductCard = ({ product }) => {
  const subscription = useSubscription();
  const { isShared } = subscription || {};

  if (!product) return null;
  return (
    <Card p="sm" shadow="none" withBorder bg="whitesmoke">
      <Group justify="space-between" mb="xs">
        <Text>{product.name}</Text>
        <Badge color={getBadgeColour(subscription?.status)} tt="capitolize">
          {SUBSCRIPTION_STATUS?.[subscription?.status] || 'Inactive'}
        </Badge>
      </Group>
      {isShared ? <OwnerDetails /> : <EndingDetails />}
    </Card>
  );
};

const SubscriptionCard = ({ ...props }) => {
  const { product, loading } = useStripeProduct();
  const subscription = useSubscription();
  const { reloadSubscription } = useSessionContext();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sessionId = params.get('session_id');
    if (sessionId) {
      reloadSubscription();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomCard title="Subscription" isLoading={loading} {...props}>
      {product ? <ProductCard product={product} /> : <ViewPlan />}
      {subscription?.customer && <ManageBilling mt="xs" />}
    </CustomCard>
  );
};

export default SubscriptionCard;
