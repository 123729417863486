const StopPropagation = ({ children }) => {
  const handleClick = (e) => {
    e.stopPropagation();
  };

  return (
    <span onClick={handleClick} style={{ display: 'contents' }}>
      {children}
    </span>
  );
};

export default StopPropagation;
