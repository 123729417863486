import { Textarea, TextInput } from '@mantine/core';

import Form from '../common/Form';
import ExperienceSelect from '../common/inputs/ExperienceSelect';
import DurationSelect from '../common/inputs/DurationSelect';

const inputProps = {
  size: 'md',
  mb: 'md',
};

const selectProps = {
  ...inputProps,
  clearable: true,
};

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Name is required';
  }

  if (!values.experience_id) {
    errors.experience_id = 'Experience is required';
  }

  if (!values.duration_id) {
    errors.duration_id = 'Duration is required';
  }

  return errors;
};

const TemplateForm = (props) => {
  return (
    <Form validate={validate} {...props}>
      <TextInput source="name" label="Name" placeholder="Enter a name" data-autofocus {...inputProps} />
      <Textarea source="description" label="Description" placeholder="Enter a description" autosize minRows={4} {...inputProps} />
      <ExperienceSelect source="experience_id" label="Experience" {...selectProps} />
      <DurationSelect source="duration_id" label="Duration" {...selectProps} />
    </Form>
  );
};

export default TemplateForm;
