import { useDisclosure } from '@mantine/hooks';

import { editRecordWithFileUpload, createRecordWithFileUpload } from '../../utils/fileUploadWithRecord';

import { CreateModal, EditModal, OpenAddModalButton, OpenEditModalButton } from '../common/ResourceModal';
import StopPropagation from '../common/StopPropagation';

import DrillForm from './DrillForm';

export const AddDrillDialog = (props) => (
  <CreateModal {...props} size="xl" submitFn={(values, resource) => createRecordWithFileUpload(values, resource, 'video')}>
    <DrillForm />
  </CreateModal>
);

export const EditDrillDialog = (props) => (
  <EditModal
    {...props}
    size="xl"
    submitFn={(values, initialValues, resource) => editRecordWithFileUpload(values, initialValues, resource, 'video')}
  >
    <DrillForm />
  </EditModal>
);

export const AddDrillDialogButton = (props) => {
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <StopPropagation>
      <OpenAddModalButton onClick={open} />
      <AddDrillDialog opened={opened} onClose={close} {...props} />
    </StopPropagation>
  );
};

export const EditDrillDialogButton = (props) => {
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <StopPropagation>
      <OpenEditModalButton onClick={open} iconOnly />
      <EditDrillDialog opened={opened} onClose={close} {...props} />
    </StopPropagation>
  );
};
