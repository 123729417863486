import { BackgroundImage, Center, Container } from '@mantine/core';
import { Navigate } from 'react-router-dom';

import useMediaQuery from '../hooks/useMediaQuery';
import { AuthenticationForm } from '../components/login/AuthenticationForm';
import { useUser } from '../context/SessionContext';
import { isLoggedIn } from '../components/auth/LoggedInRoutes';

export default function Login({ register = false }) {
  const user = useUser();
  const isMobile = useMediaQuery('sm');

  if (isLoggedIn(user)) {
    return <Navigate to="/" replace />;
  }

  return (
    <BackgroundImage
      style={{ height: 'calc(100dvh - var(--app-shell-header-height))', boxShadow: 'inset 0 0 0 1000px rgba(26, 20, 100, .3)' }}
      src="https://sngperformance.com/wp-content/uploads/2024/03/Andrei-Vasilevskiy-scaled.jpg"
    >
      <Center h="100%">
        <Container size="xs" style={{ marginLeft: !isMobile && 100 }}>
          <AuthenticationForm register={register} />
        </Container>
      </Center>
    </BackgroundImage>
  );
}
