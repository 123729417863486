import supabase from '../../../config/supabaseClient';

export const fetchExperiences = () => {
  return supabase
    .from('experience')
    .select('*')
    .order('order')
    .throwOnError()
    .then(({ data }) => data);
};

export const fetchDurations = () => {
  return supabase
    .from('duration')
    .select('*')
    .throwOnError()
    .then(({ data }) => data);
};

export const fetchTemplates = (level, duration) => {
  return supabase
    .from('template')
    .select('*, experience (*), duration (name), template_member!inner(*, category (name, group))')
    .eq('experience_id', level)
    .eq('duration_id', duration)
    .throwOnError()
    .then((res) => res.data);
};

export const fetchTemplateMembers = (templateId) => {
  return supabase
    .from('template_member')
    .select('*, category (*))')
    .eq('template_id', templateId)
    .order('order')
    .throwOnError()
    .then(({ data }) => data);
};
