import { useEffect } from 'react';
import { notifications } from '@mantine/notifications';
import { Center } from '@mantine/core';

import supabase from '../../config/supabaseClient';

const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;

export function GoogleButton() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;

    script.onload = () => {
      // Ensure `window.google.accounts.id` is available before rendering the button
      if (window.google && window.google.accounts && window.google.accounts.id) {
        window.google.accounts.id.initialize({
          client_id: clientId,
          callback: handleSignInWithGoogle,
        });

        window.google.accounts.id.renderButton(document.getElementById('buttonDiv'), {
          type: 'standard',
          shape: 'pill',
          theme: 'outline',
          text: 'signin_with',
          size: 'large',
          logo_alignment: 'left',
        });

        window.google.accounts.id.prompt();
      }
    };

    script.onerror = () => {
      console.error('Failed to load Google API script.');
    };

    document.body.appendChild(script);
  }, []);

  const handleSignInWithGoogle = async (response) => {
    const { error } = await supabase.auth.signInWithIdToken({
      provider: 'google',
      token: response.credential,
    });

    if (error) {
      console.error('Error logging in with Google', error);
      return notifications.show({
        title: 'Error',
        message: 'Error logging in with Google',
        color: 'red',
      });
    }

    window.location.href = '/';
  };

  return (
    <Center>
      <div id="buttonDiv" style={{ minHeight: '40px' }}></div>
    </Center>
  );
}
