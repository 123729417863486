import { TextInput } from '@mantine/core';

import Form from '../common/Form';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Name is required';
  }

  return errors;
};

const DurationForm = (props) => {
  return (
    <Form validate={validate} {...props}>
      <TextInput source="name" label="Name" placeholder="Enter a name" size="md" mb="md" data-autofocus />
    </Form>
  );
};

export default DurationForm;
