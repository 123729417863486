import { useMemo } from 'react';
import { Container, Group } from '@mantine/core';

import PageHeader from '../components/common/PageHeader';
import DataTable from '../components/common/DataTable';
import { AddExperienceDialogButton, EditExperienceDialogButton } from '../components/experience/ExperienceDialog';
import { DeleteModalButton } from '../components/common/DeleteModal';

export default function AdminExperiences() {
  const columns = useMemo(
    () => [
      {
        accessor: 'name',
      },
      {
        accessor: 'years',
      },
      {
        accessor: 'order',
      },
      {
        accessor: 'created_at',
        title: 'Created At',
      },
      {
        accessor: 'actions',
        title: '',
        textAlign: 'right',
        render: (record) => (
          <Group justify="right" wrap="no-wrap">
            <EditExperienceDialogButton initialValues={record} />
            <DeleteModalButton record={record} />
          </Group>
        ),
      },
    ],
    [],
  );

  return (
    <Container size="xl" p={0}>
      <PageHeader title="Experiences" actions={[<AddExperienceDialogButton key="create-experience" />]} />
      <DataTable columns={columns} sortBy="order" sortAscending />
    </Container>
  );
}
