import { useContext, useState } from 'react';
import {
  ActionIcon,
  Box,
  Button,
  CloseButton,
  CloseIcon,
  Divider,
  Drawer,
  Group,
  Input,
  Slider,
  Stack,
  TextInput,
} from '@mantine/core';
import { useDebouncedCallback } from '@mantine/hooks';
import { IconFilter, IconFilterFilled, IconSearch, IconX } from '@tabler/icons-react';

import useMediaQuery from '../../hooks/useMediaQuery';
import { DrillsContext } from './DrillsProvider';
import CategorySelect from '../common/inputs/CategorySelect';
import LevelSelect from '../common/inputs/LevelSelect';

export const SearchInput = (props) => {
  const { filters, handleFiltersChange } = useContext(DrillsContext);
  const [search, setSearch] = useState(filters?.name || '');

  const handleSearch = useDebouncedCallback((value) => handleFiltersChange('name', value), 300);

  const handleChange = (event) => {
    const value = event.currentTarget.value;
    setSearch(value);
    handleSearch(value);
  };

  const handleClear = () => {
    setSearch('');
    handleFiltersChange('name', '');
  };

  return (
    <TextInput
      placeholder="Search drills"
      value={search}
      onChange={handleChange}
      size="md"
      flex={1}
      leftSection={<IconSearch size={18} />}
      rightSection={search && <CloseButton variant="transparent" onClick={handleClear} />}
      {...props}
    />
  );
};

const ShootersSlider = ({ value, onChange, size, ...props }) => (
  <Input.Wrapper label="Shooters" size={size} mb={40}>
    <Slider
      value={value}
      onChange={onChange}
      size="lg"
      min={1}
      max={3}
      step={1}
      marks={[
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3+' },
      ]}
      {...props}
    />
  </Input.Wrapper>
);

const MobileFilters = () => {
  const isMobile = useMediaQuery('sm');
  const { filters, hasFilters, initialFilters, setFilters, clearFilters } = useContext(DrillsContext);
  const [filtersToApply, setFiltersToApply] = useState(filters);
  const [drawerOpened, setDrawerOpened] = useState(false);

  const hasFiltersToApply = Object.keys(filtersToApply).some((key) => key !== 'name' && filtersToApply[key] !== filters[key]);

  const handleClose = () => {
    setDrawerOpened(false);
    setFiltersToApply(filters);
  };

  const handleClear = () => {
    clearFilters();
    setFiltersToApply(initialFilters);
  };

  const handleApplyFilters = () => {
    setFilters(filtersToApply);
    setDrawerOpened(false);
  };

  const handleFilterChange = (key, value, toggle = true) =>
    setFiltersToApply((current) => ({ ...current, [key]: toggle && filtersToApply[key] === value ? null : value }));

  const Icon = hasFilters ? IconFilterFilled : IconFilter;

  if (!isMobile) return null;

  return (
    <Box mb="md">
      <Group gap={4}>
        <SearchInput />
        <ActionIcon c="sng" size="input-md" variant="default" onClick={() => setDrawerOpened(true)}>
          <Icon size={24} />
        </ActionIcon>
        {hasFilters && (
          <ActionIcon c="red" size="input-md" variant="default" onClick={handleClear}>
            <IconX size={24} />
          </ActionIcon>
        )}
      </Group>
      <Drawer
        radius="md"
        hiddenFrom="md"
        position="bottom"
        opened={drawerOpened}
        onClose={handleClose}
        title="Drill Filters"
        offset={8}
        styles={{
          title: { fontSize: '1.25rem', fontWeight: 500 },
          content: { height: 'auto' },
        }}
      >
        <Divider mb="md" />
        <Stack gap="lg" px="xs">
          <CategorySelect
            value={filtersToApply?.category}
            onChange={(value) => handleFilterChange('category', value)}
            defaultEnabled={false}
            size="md"
          />
          <LevelSelect value={filtersToApply?.level} onChange={(value) => handleFilterChange('level', value)} chips size="md" />
          <ShootersSlider
            value={filtersToApply?.shooters}
            onChange={(value) => handleFilterChange('shooters', value, false)}
            size="md"
          />
        </Stack>
        <Divider mb="md" />
        <Group grow justify="space-between">
          <Button
            color="red"
            fullWidth
            onClick={handleClear}
            leftSection={<CloseIcon size={16} />}
            disabled={!hasFiltersToApply && !hasFilters}
          >
            Clear
          </Button>
          <Button variant="outline" fullWidth onClick={handleApplyFilters} disabled={!hasFiltersToApply}>
            Apply
          </Button>
        </Group>
      </Drawer>
    </Box>
  );
};

export default MobileFilters;
