import { HomeIcon, DiscussionIcon, AdminIcon, PracticeIcon, DrillIcon } from '../icons';

import { ADMIN, SYSTEM_ADMIN } from '../constants';

export const tabs = [
  { link: '/', label: 'Home', icon: HomeIcon, color: 'sng', protectedRoute: true },
  { link: '/practices', label: 'Practices', icon: PracticeIcon, color: 'sng', protectedRoute: true },
  { link: '/drills', label: 'Drills', icon: DrillIcon, color: 'sng', protectedRoute: true },
  { link: '/discussions', label: 'Discussions', icon: DiscussionIcon, color: 'sng', protectedRoute: true },
  {
    link: '/admin-announcements',
    label: 'Announcements',
    icon: AdminIcon,
    color: 'yellow',
    protectedRoute: true,
    type: ADMIN,
    divider: true,
  },
  { link: '/admin-drills', label: 'Drills', icon: AdminIcon, color: 'yellow', protectedRoute: true, type: ADMIN },
  { link: '/admin-templates', label: 'Templates', icon: AdminIcon, color: 'yellow', protectedRoute: true, type: ADMIN },
  {
    link: '/admin-durations',
    label: 'Durations',
    icon: AdminIcon,
    color: 'gray',
    protectedRoute: true,
    type: SYSTEM_ADMIN,
    divider: true,
  },
  {
    link: '/admin-experiences',
    label: 'Experiences',
    icon: AdminIcon,
    color: 'gray',
    protectedRoute: true,
    type: SYSTEM_ADMIN,
  },
  {
    link: '/admin-categories',
    label: 'Categories',
    icon: AdminIcon,
    color: 'gray',
    protectedRoute: true,
    type: SYSTEM_ADMIN,
  },
];

export default tabs;
