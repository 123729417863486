import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Box, Center, Group, Loader, Stack, Text } from '@mantine/core';
import { DataTable } from 'mantine-datatable';

import supabase from '../../config/supabaseClient';

import { DeleteModalButton } from '../common/DeleteModal';
import { AddTemplateMemberDialogButton, EditTemplateMemberDialogButton } from './TemplateMemberDialog';

const TemplateMembersCard = ({ record: template }) => {
  const { data: templateMembers, isPending } = useQuery({
    queryKey: ['template_members', template.id],
    queryFn: async () =>
      await supabase
        .from('template_member')
        .select('*, category(name, group)')
        .eq('template_id', template.id)
        .order('order', { ascending: true })
        .throwOnError()
        .then(({ data }) => data),
  });

  const columns = useMemo(
    () => [
      {
        accessor: 'category.name',
        title: 'Category',
        render: (member) => `${member.category.group ? `${member.category.group} - ` : ''}${member.category.name}`,
      },
      {
        accessor: 'order',
        textAlign: 'center',
      },
      {
        accessor: 'count',
        textAlign: 'center',
      },
      {
        accessor: 'actions',
        title: '',
        render: (member) => (
          <Group justify="right" gap="xs" wrap="nowrap">
            <EditTemplateMemberDialogButton
              resource="template_member"
              initialValues={member}
              disabledValues={(templateMembers || [])
                .map((member) => member?.category_id)
                .filter((id) => id !== member.category_id)}
            />
            <DeleteModalButton resource="template_member" record={member} />
          </Group>
        ),
      },
    ],
    [],
  );

  if (isPending || !templateMembers?.length)
    return (
      <Center h={150}>
        {isPending ? (
          <Loader size="md" />
        ) : (
          <AddTemplateMemberDialogButton resource="template_member" initialValues={{ template_id: template.id }} />
        )}
      </Center>
    );

  return (
    <Box p="sm">
      <DataTable records={templateMembers} columns={columns} mb="xs" />
      <AddTemplateMemberDialogButton
        resource="template_member"
        initialValues={{ template_id: template?.id }}
        disabledValues={(templateMembers || []).map((member) => member?.category_id)}
      />
    </Box>
  );
};

export default TemplateMembersCard;
