import { useNavigate } from 'react-router-dom';
import { Badge, Button, Card, Divider, Group, Text, Tooltip } from '@mantine/core';
import { IconList, IconUsers } from '@tabler/icons-react';

import { LEVEL_COLOR } from '../../constants';
import { formatCategory } from '../../utils/util';

const DrillCard = ({ drill, locationState }) => {
  const navigate = useNavigate();

  const { name, category, level, description, shooters } = drill;

  return (
    <Card shadow="sm" p="md" withBorder>
      <Group justify="space-between" gap="xs" mb="xs" wrap="nowrap">
        <Tooltip label={name} position="bottom">
          <Text size="lg" fw={500} truncate>
            {name}
          </Text>
        </Tooltip>
        <Badge variant="light" miw="fit-content" color={LEVEL_COLOR[level]}>
          Level {level}
        </Badge>
      </Group>
      <Divider mb="xs" />
      <Group gap="xs" mb="xs">
        <Group gap={4}>
          <IconList size={16} />
          <Text size="sm">{formatCategory(category)}</Text>
        </Group>
        <Group gap={4}>
          <IconUsers size={16} />
          <Text size="sm">{`${shooters} ${shooters > 1 ? 'shooters' : 'shooter'}`}</Text>
        </Group>
      </Group>
      <Text size="sm" lineClamp={2} mb="md" c="dimmed">
        {description}
      </Text>
      <Button mt="auto" size="xs" onClick={() => navigate(`/drills/${drill.id}`, { state: locationState })}>
        View
      </Button>
    </Card>
  );
};

export default DrillCard;
