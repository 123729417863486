import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useIsActiveSubscription, useUser } from '../../context/SessionContext';
import { isLoggedIn } from './LoggedInRoutes';

const AllRoutes = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useUser();
  const isActiveSubscription = useIsActiveSubscription();
  const [redirectTo, setRedirectTo] = useState(null);

  const shareEmail = searchParams.get('share');
  const isNewUser = searchParams.get('new');

  const sessionRedirect = window.sessionStorage.getItem('redirect');

  useEffect(() => {
    // if share param is present in url and user is not logged in / subscribed, store the redirect in session storage
    if (!shareEmail || (isLoggedIn(user) && isActiveSubscription)) return;

    window.sessionStorage.setItem('redirect', location.pathname);

    if (!isLoggedIn(user)) {
      setRedirectTo(`${isNewUser ? '/register' : '/login'}?e=${shareEmail}`);
    }
  }, [isActiveSubscription, isNewUser, location.pathname, shareEmail, user]);

  useEffect(() => {
    // if redirect is found in session storage, redirect user to that page after login / subscription
    if (!sessionRedirect || !isLoggedIn(user) || !isActiveSubscription) return;

    window.sessionStorage.removeItem('redirect');
    setRedirectTo(sessionRedirect);
  }, [shareEmail, isActiveSubscription, location.pathname, user, sessionRedirect]);

  useEffect(() => {
    if (redirectTo) {
      navigate(redirectTo, { replace: true });
    }
  }, [redirectTo, navigate]);

  return <Outlet />;
};

export default AllRoutes;
