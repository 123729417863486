import supabase from '../config/supabaseClient';

export const SUPABASE_STORAGE_URL = 'https://nhjxouitvomrkusuxdry.supabase.co/storage/v1/object/public/';

const sanitizeFileName = (fileName) => fileName.replace(/\s+/g, '_');

export const uploadFile = async (file, resource) => {
  const filePath = sanitizeFileName(file.name);

  const { error: uploadError, data: fileData } = await supabase.storage.from(resource).upload(filePath, file);

  if (uploadError) throw uploadError;

  return `${SUPABASE_STORAGE_URL}${fileData.fullPath}`;
};

export const removeFile = async (filePath, resource) => {
  const fileName = filePath.split('/').pop();
  await supabase.storage.from(resource).remove([fileName]);
};

export const createRecordWithFileUpload = async (data, resource, source) => {
  if (!resource || !source) throw new Error('Resource and source are required');

  try {
    if (data[source]) {
      const file = data[source][0];
      data[source] = await uploadFile(file, resource);
    }

    const { data: record, error: insertError } = await supabase.from(resource).insert(data).select().single();

    if (insertError) throw insertError;

    return record;
  } catch (error) {
    console.error('Create record with file failed:', error.message);
    return { error };
  }
};

export const editRecordWithFileUpload = async (data, initialValues, resource, source) => {
  if (!resource || !source) throw new Error('Resource and source are required');

  let newFilePath = null;

  try {
    if (data[source] && data[source] !== initialValues?.[source]) {
      const file = data[source][0];
      newFilePath = await uploadFile(file, resource);
      data[source] = newFilePath;

      if (initialValues?.[source]) {
        await removeFile(initialValues[source], resource);
      }
    } else if (!data[source] && initialValues?.[source]) {
      await removeFile(initialValues[source], resource);
      data[source] = null;
    }

    const { data: record, error: updateError } = await supabase
      .from(resource)
      .update(data)
      .eq('id', initialValues.id)
      .select()
      .single();

    if (updateError) throw updateError;

    return record;
  } catch (error) {
    console.error('Edit record with file failed:', error.message);

    if (newFilePath) {
      await removeFile(newFilePath, resource);
    }

    return { error };
  }
};

export const deleteRecordWithFile = async (record, resource, source) => {
  try {
    await supabase.from(resource).delete().eq('id', record.id).throwOnError();

    if (record[source]) {
      await removeFile(record[source], resource);
    }
  } catch (error) {
    console.error('Delete record with file failed:', error.message);
    return { error };
  }
};
