import { Navigate } from 'react-router-dom';

import { useUserRole } from '../../context/SessionContext';

import { hasAccess } from '../../utils/auth';

const AdminRoute = ({ element: Component, type, ...rest }) => {
  const role = useUserRole();

  return hasAccess(type, role) ? <Component {...rest} /> : <Navigate to="/" replace />;
};

export default AdminRoute;
