import { useMemo } from 'react';
import { Container, Group } from '@mantine/core';

import PageHeader from '../components/common/PageHeader';
import DataTable from '../components/common/DataTable';
import { DeleteModalButton } from '../components/common/DeleteModal';
import { AddAnnouncementDialogButton, EditAnnouncementDialogButton } from '../components/announcement/AnnouncementDialog';

import { deleteRecordWithFile } from '../utils/fileUploadWithRecord';

export default function AdminAnnouncements() {
  const handleDelete = async (record, resource) => await deleteRecordWithFile(record, resource, 'image_url');

  const columns = useMemo(
    () => [
      {
        accessor: 'title',
        width: 200,
      },
      {
        accessor: 'content',
        width: 700,
      },
      {
        accessor: 'created_at',
        title: 'Created At',
        textAlign: 'right',
      },
      {
        accessor: 'actions',
        title: '',
        textAlign: 'right',
        render: (record) => (
          <Group justify="right" wrap="no-wrap">
            <EditAnnouncementDialogButton initialValues={record} />
            <DeleteModalButton record={record} deleteFn={handleDelete} />
          </Group>
        ),
      },
    ],
    [],
  );

  return (
    <Container size="xl" p={0}>
      <PageHeader title="Announcements" actions={[<AddAnnouncementDialogButton key="create-announcement-modal" />]} />
      <DataTable columns={columns} />
    </Container>
  );
}
