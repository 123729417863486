import { TextInput } from '@mantine/core';

import Form from '../common/Form';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Name is required';
  }

  if (!values.years) {
    errors.years = 'Years is required';
  }

  return errors;
};

const ExperienceForm = (props) => {
  return (
    <Form validate={validate} {...props}>
      <TextInput source="name" label="Name" placeholder="Enter a name" size="md" mb="md" data-autofocus />
      <TextInput source="years" label="Years" placeholder="12-16 years" size="md" mb="md" />
    </Form>
  );
};

export default ExperienceForm;
