import { useQuery } from '@tanstack/react-query';
import { Badge, Card, Group, Text } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import { IconStarFilled } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

import supabase from '../../config/supabaseClient';
import CustomCard from '../common/CustomCard';
import { useUser } from '../../context/SessionContext';
import { isLoggedIn } from '../auth/LoggedInRoutes';

import classes from './FavoritePractices.module.css';

const PracticeCard = ({ practice }) => {
  const navigate = useNavigate();
  const { name, description, template } = practice;
  const { duration, experience } = template || {};

  const onClick = () => navigate(`/practices/${practice.id}`, { state: { is_favorite: true } });

  return (
    <Card h="100%" shadow="sm" padding="md" withBorder onClick={onClick} className={classes.cardHover}>
      <Group justify="space-between" gap="xs">
        <Text fw={500}>{name}</Text>
        <IconStarFilled size={16} color="orange" />
      </Group>
      <Group gap="xs" wrap="wrap" mt="xs">
        {experience?.name && <Badge variant="outline">{experience.name}</Badge>}
        {duration?.name && <Badge variant="outline">{duration.name}</Badge>}
      </Group>
      <Text size="sm" c="dimmed" mt="md" lineClamp={2}>
        {description}
      </Text>
    </Card>
  );
};

const PracticeCarousel = ({ practices = [] }) => {
  return (
    <Carousel
      slideSize={{ base: '100%', xs: '50%', md: '33.333333%' }}
      slidesToScroll={{ base: 1, xs: 2, md: 3 }}
      slideGap={{ base: 'xs', sm: 'md' }}
      containScroll="trimSnaps"
      align="center"
      classNames={classes}
    >
      {practices?.map((practice) => {
        return (
          <Carousel.Slide key={practice.id}>
            <PracticeCard practice={practice} />
          </Carousel.Slide>
        );
      })}
    </Carousel>
  );
};

const FavoritePractices = () => {
  const user = useUser();
  const {
    data: practices,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['practice', { is_favorite: true }],
    queryFn: async () =>
      await supabase
        .from('practice')
        .select('*, template (name, experience (name), duration (name)), shared_practice (*)')
        .eq('is_favorite', true)
        .throwOnError()
        .then(({ data }) => data),
    enabled: isLoggedIn(user),
  });

  if (!practices?.length || !isLoggedIn(user)) return null;

  return (
    <CustomCard title="Favorite Practices" isLoading={isLoading} isError={isError} isOutline={false}>
      <PracticeCarousel practices={practices} />
    </CustomCard>
  );
};

export default FavoritePractices;
